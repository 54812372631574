export const home = "/";
export const login = "/login";
export const register = "/register";
export const prospectCreate = "/prospect/create";
export const prospectCopy = "/prospect/:prospectId/copy";
export const prospectCreated = "/prospect/:prospectId/created";
export const prospectEmailSent = "/prospect/:prospectId/emailSent";
export const prospectsList = "/prospects";
export const prospect = prospectsList + "/:prospectId/view/:prospectViewName";
export const prospectInsurance = prospect + "/:insuranceId";
export const prospectInsuranceDetail = prospectInsurance + "/:insuranceDetailId";
export const meeting = "/meetings/:prospectReference";
export const meetingBasicInformation = meeting + "/basicInformation";
export const meetingPresentation = meeting + "/presentation";

export const loginWithReturnUrl = (returnUrl: string) => {
  return login + "?returnUrl=" + encodeURI(returnUrl);
};
