import useOmsenMaintenanceWindow from "../../hooks/useOmsenMaintenanceWindow";
import React from "react";
import moment from "moment/moment";
import {dateFormat_DDMMYYYY, dateFormat_HHmm} from "../../constants/dateFormats";

export default function OmsenMaintenanceWindow() {
  const { data } = useOmsenMaintenanceWindow();

  // No data or more than 24 hours until maintenance window start
  if (!data || !data.startTimeUTC || !data.endTimeUTC || Number(data.secondsUntilStart) > (24 * 60 * 60)) {
    return null;
  }

  const start = moment(new Date(data.startTimeUTC)).format(`${dateFormat_DDMMYYYY} ${dateFormat_HHmm}`);
  const end = moment(new Date(data.endTimeUTC)).format(`${dateFormat_HHmm}`);

  return (
    <div className={`mb-2 alert ${data.active ? 'alert-danger' : 'alert-warning'}`} role="alert">
      <div className="fs-5">{data.title} ({start} - {end})</div>
      <div className="fs-6">{data.message}</div>
    </div>
  );
}
