// SignalR Hub actions
export const SIGNALR_HUB_PROSPECT_UPDATED = "SIGNALR_HUB_PROSPECT_UPDATED";
export const SIGNALR_HUB_CONNECT = "SIGNALR_HUB_CONNECT";
export const SIGNALR_HUB_CONNECT_SUCCESS = "SIGNALR_HUB_CONNECT_SUCCESS";
export const SIGNALR_HUB_CONNECT_FAILURE = "SIGNALR_HUB_CONNECT_FAILURE";
export const SIGNALR_HUB_LISTEN_PROSPECT = "SIGNALR_HUB_LISTEN_PROSPECT";

// Authentication actions
export const AUTHENTICATION_LOGIN_USER_FROM_STORAGE = "AUTHENTICATION_LOGIN_USER_FROM_STORAGE";
export const AUTHENTICATION_REFRESH_USER = "AUTHENTICATION_REFRESH_USER";
export const AUTHENTICATION_LOGIN_USER = "AUTHENTICATION_LOGIN_USER";
export const AUTHENTICATION_LOGIN_USER_SUCCESS = "AUTHENTICATION_LOGIN_USER_SUCCESS";
export const AUTHENTICATION_LOGIN_USER_FAILURE = "AUTHENTICATION_LOGIN_USER_FAILURE";
export const AUTHENTICATION_LOGOUT_USER = "AUTHENTICATION_LOGOUT_USER";
export const AUTHENTICATION_LOGOUT_USER_SUCCESS = "AUTHENTICATION_LOGOUT_USER_SUCCESS";
export const AUTHENTICATION_LOGOUT_USER_FAILURE = "AUTHENTICATION_LOGOUT_USER_FAILURE";
export const AUTHENTICATION_REGISTER_USER = "AUTHENTICATION_REGISTER_USER";
export const AUTHENTICATION_REGISTER_USER_SUCCESS = "AUTHENTICATION_REGISTER_USER_SUCCESS";
export const AUTHENTICATION_REGISTER_USER_FAILURE = "AUTHENTICATION_REGISTER_USER_FAILURE";

// Prospect actions
export const PROSPECT_FETCH_PROSPECTS = "PROSPECT_FETCH_PROSPECTS";
export const PROSPECT_FETCH_PROSPECTS_SUCCESS = "PROSPECT_FETCH_PROSPECTS_SUCCESS";
export const PROSPECT_FETCH_PROSPECTS_FAILURE = "PROSPECT_FETCH_PROSPECTS_FAILURE";
export const PROSPECT_FETCH_ALL_PROSPECTS = "PROSPECT_FETCH_ALL_PROSPECTS";
export const PROSPECT_FETCH_ALL_PROSPECTS_SUCCESS = "PROSPECT_FETCH_ALL_PROSPECTS_SUCCESS";
export const PROSPECT_FETCH_ALL_PROSPECTS_FAILURE = "PROSPECT_FETCH_ALL_PROSPECTS_FAILURE";
export const PROSPECT_FETCH_PROSPECT = "PROSPECT_FETCH_PROSPECT";
export const PROSPECT_FETCH_PROSPECT_SUCCESS = "PROSPECT_FETCH_PROSPECT_SUCCESS";
export const PROSPECT_FETCH_PROSPECT_FAILURE = "PROSPECT_FETCH_PROSPECT_FAILURE";
export const PROSPECT_UPDATE_PROSPECT = "PROSPECT_UPDATE_PROSPECT";
export const PROSPECT_UPDATE_PROSPECT_SUCCESS = "PROSPECT_UPDATE_PROSPECT_SUCCESS";
export const PROSPECT_UPDATE_PROSPECT_FAILURE = "PROSPECT_UPDATE_PROSPECT_FAILURE";
export const PROSPECT_PUSH_PROSPECT_VIEW = "PROSPECT_PUSH_PROSPECT_VIEW";
export const PROSPECT_PUSH_PROSPECT_VIEW_SUCCESS = "PROSPECT_PUSH_PROSPECT_VIEW_SUCCESS";
export const PROSPECT_PUSH_PROSPECT_VIEW_FAILURE = "PROSPECT_PUSH_PROSPECT_VIEW_FAILURE";
export const PROSPECT_CREATE_PROSPECT = "PROSPECT_CREATE_PROSPECT";
export const PROSPECT_CREATE_PROSPECT_SUCCESS = "PROSPECT_CREATE_PROSPECT_SUCCESS";
export const PROSPECT_CREATE_PROSPECT_FAILURE = "PROSPECT_CREATE_PROSPECT_FAILURE";
export const PROSPECT_UPDATE_BASIC_INFORMATION = "PROSPECT_UPDATE_BASIC_INFORMATION";
export const PROSPECT_UPDATE_BASIC_INFORMATION_SUCCESS = "PROSPECT_UPDATE_BASIC_INFORMATION_SUCCESS";
export const PROSPECT_UPDATE_BASIC_INFORMATION_FAILURE = "PROSPECT_UPDATE_BASIC_INFORMATION_FAILURE";
export const PROSPECT_FETCH_PROSPECT_EMAIL = "PROSPECT_FETCH_PROSPECT_EMAIL";
export const PROSPECT_FETCH_PROSPECT_EMAIL_SUCCESS = "PROSPECT_FETCH_PROSPECT_EMAIL_SUCCESS";
export const PROSPECT_FETCH_PROSPECT_EMAIL_FAILURE = "PROSPECT_FETCH_PROSPECT_EMAIL_FAILURE";
export const PROSPECT_SEND_PROSPECT_EMAIL = "PROSPECT_SEND_PROSPECT_EMAIL";
export const PROSPECT_SEND_PROSPECT_EMAIL_SUCCESS = "PROSPECT_SEND_PROSPECT_EMAIL_SUCCESS";
export const PROSPECT_SEND_PROSPECT_EMAIL_FAILURE = "PROSPECT_SEND_PROSPECT_EMAIL_FAILURE";
export const PROSPECT_PUSH_ANALYTICS_DATA = "PROSPECT_PUSH_ANALYTICS_DATA";
export const PROSPECT_PUSH_ANALYTICS_DATA_SUCCESS = "PROSPECT_PUSH_ANALYTICS_DATA_SUCCESS";
export const PROSPECT_PUSH_ANALYTICS_DATA_FAILURE = "PROSPECT_PUSH_ANALYTICS_DATA_FAILURE";
export const PROSPECT_FETCH_ANALYTICS_DATA = "PROSPECT_FETCH_ANALYTICS_DATA";
export const PROSPECT_FETCH_ANALYTICS_DATA_SUCCESS = "PROSPECT_FETCH_ANALYTICS_DATA_SUCCESS";
export const PROSPECT_FETCH_ANALYTICS_DATA_FAILURE = "PROSPECT_FETCH_ANALYTICS_DATA_FAILURE";
export const PROSPECT_CLEAR_ANALYTICS_DATA = "PROSPECT_CLEAR_ANALYTICS_DATA";
export const PROSPECT_DELETE_PROSPECT = "PROSPECT_DELETE_PROSPECT";
export const PROSPECT_DELETE_PROSPECT_FAILURE = "PROSPECT_DELETE_PROSPECT_FAILURE";
export const PROSPECT_DELETE_PROSPECT_SUCCESS = "PROSPECT_DELETE_PROSPECT_SUCCESS";
export const PROSPECT_CREATE_PROSPECT_COMMENT = "PROSPECT_CREATE_PROSPECT_COMMENT";
export const PROSPECT_CREATE_PROSPECT_COMMENT_FAILURE = "PROSPECT_CREATE_PROSPECT_COMMENT_FAILURE";
export const PROSPECT_CREATE_PROSPECT_COMMENT_SUCCESS = "PROSPECT_CREATE_PROSPECT_COMMENT_SUCCESS";
export const PROSPECT_FETCH_ALL_SELLERS = "PROSPECT_GET_ALL_SELLERS";
export const PROSPECT_FETCH_ALL_SELLERS_FAILURE = "PROSPECT_GET_ALL_SELLERS_FAILURE";
export const PROSPECT_FETCH_ALL_SELLERS_SUCCESS = "PROSPECT_GET_ALL_SELLERS_SUCCESS";
