import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AppSidebar from "../AppSidebar/AppSidebar";
import Container from "react-bootstrap/Container";
import React, { Component } from "react";
import AppHeader from "../AppHeader/AppHeader";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

type TDefaultLayoutProps = {
  navigate: NavigateFunction;
  showSidebarAdminButton?: boolean;
  showSidebarUserButton?: boolean;
  showHeaderCreateCustomerButton?: boolean;
  prospectId?: string;
  prospectReference?: string;
  prospectViewName?: string;
  showHeaderButtons?: boolean;
  children: JSX.Element;
};

type TDefaultLayoutState = {};

class DefaultLayoutWithParams extends Component<TDefaultLayoutProps, TDefaultLayoutState> {
  render() {
    const prospectViewName = this.props.prospectViewName || "basic";
    const showSidebarAdminButton =
      this.props.showSidebarAdminButton !== undefined ? this.props.showSidebarAdminButton : false;
    const showSidebarUserButton =
      this.props.showSidebarUserButton !== undefined ? this.props.showSidebarUserButton : false;
    const showHeaderCreateCustomerButton =
      this.props.showHeaderCreateCustomerButton !== undefined ? this.props.showHeaderCreateCustomerButton : true;

    return (
      <Container className={"DefaultLayout"}>
        <Row>
          <Col>
            <AppHeader
              showHeaderButtons={this.props.showHeaderButtons ?? false}
              prospectId={this.props.prospectId}
              prospectReference={this.props.prospectReference}
              prospectViewName={prospectViewName}
              showHeaderCreateCustomerButton={showHeaderCreateCustomerButton}
            />
          </Col>
        </Row>
        <Row>
          <Col>{this.props.children}</Col>
        </Row>
        <AppSidebar
          navigate={this.props.navigate}
          showSidebarAdminButton={showSidebarAdminButton}
          showSidebarUserButton={showSidebarUserButton}
          prospectId={this.props.prospectId}
          prospectReference={this.props.prospectReference}
          prospectViewName={prospectViewName}
        />
      </Container>
    );
  }
}

export const DefaultLayout = (
  { showHeaderCreateCustomerButton,
    showSidebarAdminButton,
    showSidebarUserButton,
    calculateShowAdminButton,
    calculateShowUserButton,
    showHeaderButtons,
    children,
  }:
    {
      showHeaderCreateCustomerButton?: boolean,
      showSidebarAdminButton?: boolean,
      showSidebarUserButton?: boolean,
      calculateShowAdminButton?: boolean,
      calculateShowUserButton?: boolean,
      showHeaderButtons?: boolean,
      children: JSX.Element
    }) => {
  const navigate = useNavigate();
  const { prospectId, prospectViewName, prospectReference } = useParams();
  const showAdminButton = calculateShowAdminButton
    ? prospectId !== undefined
    : showSidebarAdminButton;
  const showUserButton = calculateShowUserButton
    ? prospectId !== undefined
    : showSidebarUserButton;
  return (
    <DefaultLayoutWithParams
      navigate={navigate}
      showSidebarAdminButton={showAdminButton}
      showSidebarUserButton={showUserButton}
      showHeaderCreateCustomerButton={showHeaderCreateCustomerButton}
      prospectId={prospectId}
      prospectReference={prospectReference}
      prospectViewName={prospectViewName}
      showHeaderButtons={showHeaderButtons}
      children={children}
    />
  )
}
