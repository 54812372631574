import { IAuthenticationService, TBooleanResponse, TUserResponse } from "../contracts/services/IAuthenticationService";
import { IApiService } from "../contracts/services/IApiService";
import { IUser } from "../contracts/data/IUser";
import { IUserRegistrationData } from "../contracts/data/IUserRegistrationData";
import * as endpoints from "../constants/endpoints";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { hasExpired } from "../utils/appUtils";

type TAuthenticateCommand = {
  username: string;
  password: string;
};

type TAuthenticateResponse = {
  accessToken: string;
  refreshToken: string;
};

type TRegisterCommand = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  registrationCode: string;
};

type TRegisterResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

type TRefreshCommand = {
  accessToken: string;
  refreshToken: string;
};

export class AuthenticationService implements IAuthenticationService {
  private apiService: IApiService;

  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async login(email: string, password: string): Promise<TUserResponse> {
    const payload: TAuthenticateCommand = {
      username: email,
      password,
    };
    const authenticateResponse = await this.apiService.post<TAuthenticateResponse>(endpoints.login, payload);

    if (authenticateResponse.data) {
      return { data: this.mapAuthenticateResponseToUser(authenticateResponse.data) };
    }

    return {
      errors: authenticateResponse.errors,
    };
  }

  async logout(user: IUser): Promise<TBooleanResponse> {
    return { data: true };
  }

  async refresh(user: IUser): Promise<TUserResponse> {
    const payload: TRefreshCommand = {
      accessToken: user.tokens.accessToken,
      refreshToken: user.tokens.refreshToken,
    };

    const expirationDate = moment(new Date(user.tokens.accessTokenExpiration * 1000));
    if (!hasExpired(expirationDate, moment(), 15)) {
      // No need to refresh the token yet unless it will expire within 15 minutes
      return { data: user };
    }

    const authenticateResponse = await this.apiService.post<TAuthenticateResponse>(endpoints.refresh, payload);

    if (authenticateResponse.data) {
      return { data: this.mapAuthenticateResponseToUser(authenticateResponse.data) };
    }

    return {
      errors: authenticateResponse.errors,
    };
  }

  async register(registrationData: IUserRegistrationData): Promise<TUserResponse> {
    const payload: TRegisterCommand = {
      ...registrationData,
    };
    const registerResponse = await this.apiService.post<TRegisterResponse>(endpoints.register, payload);

    if (registerResponse.data) {
      const registeredUser: IUser = {
        tokens: {
          accessToken: "",
          accessTokenExpiration: 0,
          refreshToken: "",
        },
        ...registerResponse.data,
      };

      return {
        data: registeredUser,
      };
    }

    return {
      errors: registerResponse.errors,
    };
  }

  async validate(user: IUser): Promise<TUserResponse> {
    return await this.refresh(user);
  }

  private mapAuthenticateResponseToUser(response: TAuthenticateResponse): IUser {
    type TTokenClaims = {
      FirstName: string;
      FullName: string;
      LastName: string;
      role: string;
      unique_name: string;
      exp: number;
      iat: number;
      nbf: number;
      nameid: string;
      email: string;
    };

    const jwtToken: TTokenClaims = jwt_decode(response.accessToken);

    const user: IUser = {
      id: jwtToken.nameid,
      email: jwtToken.email,
      firstName: jwtToken.FirstName,
      lastName: jwtToken.LastName,
      tokens: {
        accessToken: response.accessToken,
        accessTokenExpiration: jwtToken.exp,
        refreshToken: response.refreshToken,
      },
    };

    return user;
  }
}
