import React from "react";
import { Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { withTranslation } from "react-i18next";

const FormikInput = ({ ...props }: any) => {
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const isInvalid = Boolean(!!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched);
  let currentValue = formikProps.getFieldProps(field.name).value;

  if (currentValue === null) {
    if (props.type === undefined || props.type === "text" || props.type === "number") {
      currentValue = "";
    } else {
      currentValue = undefined;
    }
  }

  if (props.zeroAsEmpty && props.type === "number" && currentValue === 0) {
    currentValue = "";
  }

  return (
    <Form.Group className={"FormikInput form-group"} as={props.as ?? undefined} xs={props.xs ?? undefined}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <div className={(props.unit !== undefined ? "input-group" : "") + (isInvalid ? " is-invalid" : "")}>
        <Form.Control
          name={field.name}
          type={props.type !== undefined ? props.type : "text"}
          placeholder={""}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          value={currentValue}
          isInvalid={isInvalid}
          disabled={props.disabled}
        />
        {props.unit !== undefined ? (
          <span className="input-group-text">{props.unit}</span>
        ) : null}
      </div>
      <Form.Control.Feedback type="invalid">{String(formikProps.getFieldMeta(field.name).error)}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikInput);
