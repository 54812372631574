import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { ISeller } from "../../contracts/data/ISeller";
import { getFullName } from "../../utils/appUtils";
import {ReactComponent as ClearIcon} from "../../assets/times_solid.svg";


const ProspectTableFilters = ({ ...props }: any) => {
    const { t } = props;

    const handleSearch = (value: string) => {
        props.inputSearch(value)
    }

    const handleFocusChange = (focus: boolean) => {
        props.inputFocusChange(focus);
    }

    const handleToggleClick = (viewAll: boolean) => {
        props.toggleViewAll(viewAll);
    }

    const handleSelectChange = (value: string) => {
        props.selectOnChange(value);
    }

    const renderSellerOptions = () => {
      return (props.sellers.map((seller: ISeller) => {
        if (seller !== null) return (<option key={seller.id} value={seller.id}>{getFullName(seller.firstName, seller.lastName)}</option>);
        return null;
      }));
    }

    return (
            <div className={'form-row table-filters'}>
              <div className={'form-group me-5'}>
                <label className={"form-label"}>{t("Search")}</label>
                <InputGroup className={"mb-3"}>
                    <FormControl
                      className={"filter-input"}
                      placeholder={t("Name, company or Business ID")}
                      onChange={(e:any) => handleSearch(e.target.value)}
                      value={props.inputValue !== "*" ? props.inputValue : ""}
                      autoFocus={props.inputAutoFocus}
                      onFocus={(e: any) => handleFocusChange(true)}
                      onBlur={(e: any) => handleFocusChange(false)}
                    />
                    <InputGroup.Text className={"clear-find-prospects btn-info border"} onClick={(e: any) => handleSearch("")} id={"basic-addon"}>
                      <ClearIcon />
                    </InputGroup.Text>
                </InputGroup>
              </div>
              <div className={'form-group me-5'}>
                <div className={"mb-2"}>{t("Customers")}</div>
                <div className={'btn-group btn-group-justified'} data-toggle={"buttons"}>
                  <button onClick={(e: any) => handleToggleClick(true)} id={'all-customers'} className={props.toggleAllButtonClass}>{t("All")}</button>
                  <button onClick={(e: any) => handleToggleClick(false)} id={'my-customers'} className={props.toggleOwnButtonClass}>{t("My own")}</button>
                </div>
              </div>
              {props.showFilterSeller? (
                <div className={'form-group me-5'}>
                  <label className={"form-label"}>{t("Filter seller")}</label>
                  <select className={"form-control"}
                          name={"filter-seller"}
                          onChange={(e: any) => {handleSelectChange(e.target.value)}}
                          value={props.selectValue}>
                    [<option key={'*'} value={"*"}>{t("All sellers")}</option>];
                    {renderSellerOptions()}
                  </select>
                </div>):null}
              </div>
      );

}

export default withTranslation("translations")(ProspectTableFilters);
