import React from "react";

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { IProspectFetch } from "../../contracts/data/IProspectFetch";
import { Table } from "react-bootstrap";
import TablePagination from "./TablePagination";

type TColumnType = {
  fullName: string;
  companyId: string;
  companyName: string;
  companyCity: string;
  fiscalMonth: string;
  meetingDate: string;
  created: string;
  salesState: string;
  historyLink: string;
  prospectLinks: string;
};

export type TTableListProps = {
  title?: string;
  data: any[];
  columnsData: any[];
  payload: IProspectFetch;
  updateTable: (type: any, newState: any) => void;
  totalSize: number;
};

const TableList = ({ title, data, columnsData, payload, updateTable, totalSize }: TTableListProps) => {

  const columnHelper = createColumnHelper<TColumnType>();
  const columns = columnsData.map((cd, index) => {
    return columnHelper.accessor(cd.accessor, {
      id: cd.accessor,
      header: () => cd.header,
      cell: cd.cell,
      enableSorting: cd.sortable,
    });
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="TableList">
      {title ? <h2>{title}</h2> : null}
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={`${header.column.columnDef.enableSorting === false ? "disabled" : "enable"}`}
                        onClick={() =>
                          updateTable("sort", {
                            ...payload,
                            sortField: header.id,
                            sortOrder: payload.sortOrder === "desc" ? "asc" : "desc",
                          })
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.columnDef.enableSorting !== false ? (
                          <span
                            className={`${
                              header?.id === payload.sortField ? (payload.sortOrder === "desc" ? "desc" : "asc") : ""
                            }`}
                          ></span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <TablePagination
        updateTable={updateTable}
        payload={payload}
        sizePerPageOptions={[10, 15, 20, 40, 80]}
        totalSize={totalSize}
      />
    </div>
  );
};

export default TableList;
