import { IStorageService } from "../contracts/services/IStorageService";
import { IUser } from "../contracts/data/IUser";

export class LocalStorageService implements IStorageService {
  private static readonly STORAGE_PREFIX = "insurance_calculator";
  private static readonly USER_KEY = "user";

  clearCurrentUser(): void {
    localStorage.removeItem(this.getCurrentUserStorageKey());
  }

  getCurrentUserStorageKey(): string {
    return LocalStorageService.generateStorageKey(LocalStorageService.USER_KEY);
  }

  getCurrentUser(): IUser | null {
    const userJson = localStorage.getItem(this.getCurrentUserStorageKey());
    if (userJson === null) {
      return null;
    }
    const user: IUser | null = JSON.parse(userJson);
    if (user !== null && user.tokens === undefined) {
      return null;
    }
    return user;
  }

  setCurrentUser(user: IUser): void {
    localStorage.setItem(this.getCurrentUserStorageKey(), JSON.stringify(user));
  }

  private static generateStorageKey(key: string): string {
    return LocalStorageService.STORAGE_PREFIX + "_" + key;
  }
}
