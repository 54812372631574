import React, { useState } from "react";
import { Button, Card, CloseButton, Form, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { IProspectHistory } from "../../contracts/data/IProspectHistory";
import { SalesState } from "../../contracts/data/IProspectBase";
import { getFullName } from "../../utils/appUtils";
import { ISeller } from "../../contracts/data/ISeller";

const HistoryPopover = ({ ...props }: any) => {
  const { t } = props;
  const [textField, setTextField] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResultMessage, setSubmitResultMessage] = useState("");
  const [submitResultSuccess, setSubmitResultSuccess] = useState(true);

  const handleChange = (e: any) => {
    setTextField(e.target.value)
  }

  const handleSubmit = async (e: any) => {
    setIsSubmitting(true);
    const result = await props.createComment(props.prospect.id, textField);
    if(result){
      setIsSubmitting(false);
      setSubmitResultSuccess(true);
      setSubmitResultMessage(t("Successfully saved comment"))
      setTextField("");
      props.commentCreated();
    }
    else {
      setSubmitResultSuccess(false);
      setIsSubmitting(false);
      setSubmitResultMessage(t("Unable to save"))
    }
    setTimeout(() => {
        setSubmitResultMessage("");
    }, 2500);
  }

  const renderDate = (date: Date) => {
    return new Date(date).toLocaleDateString("fi", {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'});
  }

  const getSeller = (sellerId: any) => {
    return props.sellers.find((seller: ISeller) => seller.id === sellerId);
  }

  const orderedHistory = props.prospect.prospectHistories.sort((a: IProspectHistory, b: IProspectHistory) => (a.modifiedDate > b.modifiedDate) ? -1 : 1);

  const getHistoryTexts = (oldValueDB: string, newValueDB: string) => {
    let title = "";
    const firstTextArray = [];
    const secondTextArray = [];
    const oldKey = Object.keys(JSON.parse(oldValueDB))[0];
    const oldValue = Object.values(JSON.parse(oldValueDB))[0];
    const newValue = Object.values(JSON.parse(newValueDB))[0];
    switch (oldKey){
      case ('SalesState'):
        title = t("Sales state changed");
        firstTextArray.push(t("Previous state"));
        const salesStateOv = oldValue? t(SalesState[Number(oldValue)]) : t("Not set");
        firstTextArray.push(salesStateOv);
        secondTextArray.push(t("New state"));
        secondTextArray.push(t(SalesState[Number(newValue)]));
        break;
      case ('SellerId'):
        title = t("Seller changed");
        firstTextArray.push(t("Previous seller"));
        const oldSeller = getSeller(oldValue);
        const newSeller = getSeller(newValue);
        firstTextArray.push(getFullName(oldSeller.firstName, oldSeller.lastName));
        secondTextArray.push(t("New seller"));
        secondTextArray.push(getFullName(newSeller.firstName, newSeller.lastName));
        break;
      case ('Comment'):
        title = t("New comment");
        const seller = getSeller(newValue);
        const name = getFullName(seller.firstName, seller.lastName);
        firstTextArray.push(t(name + t("wrote")));
        secondTextArray.push(oldValue);
        break;
    }
    return {title, firstTextArray, secondTextArray};
  }

  const renderCard = (h: IProspectHistory, i: number) => {
    const texts = getHistoryTexts(h.oldValue, h.newValue);
    const cardStyle = (i === 0 && props.newCard)? "primary": "";

    return(
    <Card border={cardStyle} key={h.id} className={'mb-2'}>
      <Card.Header as={"h5"}>{texts.title} {renderDate(h.modifiedDate)}</Card.Header>
      <Card.Body>
        <Card.Text>
          <span>{texts.firstTextArray}</span>
          <span className={'mt-2'}>{texts.secondTextArray}</span>
        </Card.Text>
      </Card.Body>
    </Card>);
  }
  const spinner = (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
              </>);

  const renderSubmitButton = () => {
    let variant = "";
    if (submitResultMessage && submitResultSuccess) variant = "success";
    else if (submitResultMessage && !submitResultSuccess) variant = "danger";
    else variant = "primary";
    return(
      <Button onClick={e => handleSubmit(e)} variant={variant} type="submit" className={"w-100"} disabled={isSubmitting}>
        {isSubmitting ? spinner : null}
        {submitResultMessage? submitResultMessage: t("Save")}
      </Button>
    );
  }

  return <div className={'popover-content px-3'}>
        <CloseButton onClick={() => document.body.click()}/>
        <h2 className={"mt-2"}>{getFullName(props.prospect.firstName, props.prospect.lastName)}</h2>
        <h4 className={'mt-2'}>{t("History")}</h4>
        {orderedHistory.map((h: IProspectHistory, i: number) => (
          renderCard(h, i)
        ))}
        <Form.Group className="form-group">
              <Form.Label>{t("New comment")}</Form.Label>
              <Form.Control
                name={"comments"}
                as={"textarea"}
                placeholder={""}
                onChange={e => handleChange(e)}
                rows={10}
                value={textField}
              />
            </Form.Group>
            <Form.Group className="form-group">
              {renderSubmitButton()}
            </Form.Group>
    </div>

}

export default withTranslation("translations")(HistoryPopover);
