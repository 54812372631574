import { IInsuranceDetail } from "./IInsuranceDetail";

export enum InsuranceType {
  Unknown = 1,
  Current,
  Comparison,
  Offer,
  Alternative,
  Omsen,
}

export enum InsurancePaymentInterval {
  OneTime = 1,
  TwoTimes = 2,
  FourTimes = 4,
  TwelveTimes = 12,
}

export enum InsuranceExpectedNetRate {
  zeroPercent = 0,
  TwoPointThirtyFivePercent = 235,
  ThreePercent = 300,
  ThreePointFivePercent = 350,
  FivePercent = 500,
  SixPointFivePercent = 650,
  EightPercent = 800,
  TenPercent = 1000,
}

export interface IInsurance {
  id: string;
  type: InsuranceType;
  isVisible: boolean;
  isTotalsVisible: boolean;
  annualIncomeYel: number | null;
  annualCost: number;
  monthlyCost: number;
  annualCostYel: number;
  monthlyCostYel: number;
  optionalSicknessPayDeductibleDays: number;
  optionalSicknessDuration: number;
  optionalSicknessPayAmount: number | null;
  optionalDisabilityPensionAmount: number | null;
  optionalLifeInsuranceAmount: number | null;
  optionalSeriousIllnessInsuranceAmount: number;
  optionalExpectedNetRate: number;
  optionalOldAgeExtraPensionAnnualCost: number;
  paymentInterval: number;
  details: IInsuranceDetail[];
}
