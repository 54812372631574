import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "../../assets/delete_icon.svg";
import { ReactComponent as EditIcon } from "../../assets/edit_icon.svg";
import FormikInput from "./FormikInput";
import FormikCountryPicker from "./FormikCountryPicker";
import FormikLanguagePicker from "./FormikLanguagePicker";
import { withTranslation } from "react-i18next";
import { Formik, useField, useFormikContext } from "formik";
import AppSidebarContent from "../AppSidebar/AppSidebarContent";
import { createPortal } from "react-dom";
import * as Yup from "yup";
import { DomainEntitiesOmsenDataProposalKycRealBeneficiary } from "../../api";
import { v4 as uuidv4 } from "uuid";
import { OmsenState } from "../../contracts/data/IProspect";
import NaturalPerson from './NaturalPerson';

const Beneficiaries = ({ ...props }: any) => {
  const [isEditing, setIsEditing] = useState<{ index: number; isOpen: boolean }>({ index: 0, isOpen: false });
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const { t, name } = props;

  const deleteBeneficiary = async (id: string) => {
    formikProps.setFieldError(name, "");
    await formikProps.setFieldValue(
      name,
      formikProps
        .getFieldProps(field.name)
        .value.filter((beneficiary: DomainEntitiesOmsenDataProposalKycRealBeneficiary) => beneficiary.id !== id),
    );
  };

  const handleEdit = (index?: number, close?: boolean) => {
    const element = document.getElementById("seller-content-tabs-tabpane-questions");
    setIsEditing({ index: index ?? 0, isOpen: !isEditing?.isOpen });
    const newDisplayStyle = close ? "" : "none";

    if (element) {
      element.style.display = newDisplayStyle;
      element.scrollTo();
    }
  };

  const sideBarDom = document.getElementById("AppSidebar-contents");

  const addBeneficiary = (index: number) => {
    const beneficiary = formikProps.getFieldProps(`${name}[${index}]`).value;

    const getValueOrNull = (data: string) => {
      return data !== undefined ? data : null
    }

    const initialValues: DomainEntitiesOmsenDataProposalKycRealBeneficiary = {
      id: beneficiary?.id,
      fullName: getValueOrNull(beneficiary?.fullName),
      ssn: getValueOrNull(beneficiary?.ssn),
      postalAddress: getValueOrNull(beneficiary?.postalAddress),
      postalCode: getValueOrNull(beneficiary?.postalCode),
      city: getValueOrNull(beneficiary?.city),
      countryCode:getValueOrNull( beneficiary?.countryCode),
      languageCode: getValueOrNull(beneficiary?.languageCode),
      citizenshipNumbers: beneficiary?.citizenshipNumbers ?? [],
      positionInCompanyInfo: getValueOrNull(beneficiary?.positionInCompanyInfo),
      shareHoldingInfo: getValueOrNull(beneficiary?.shareHoldingInfo),
      politicalExposedPerson: beneficiary?.politicalExposedPerson ?? false,
      taxDomicileCode: getValueOrNull(beneficiary?.taxDomicileCode),
      countryOfBirthNumber: beneficiary?.countryOfBirthNumber ?? 0,
      phone: getValueOrNull(beneficiary?.phone),
      email: getValueOrNull(beneficiary?.email)
    };

    const validationSchema = Yup.object().shape({
      fullName: Yup.string().required("Name is required"),
      ssn: Yup.string().required("Ssn is required"),
      postalAddress: Yup.string().required("Postal address is required"),
      postalCode: Yup.string().required("Postal code is required"),
      city: Yup.string().required("City is required"),
      countryCode: Yup.string().required("County is required"),
      languageCode: Yup.string().required("Language is required"),
      citizenshipNumbers: Yup.array().of(Yup.number()).min(1, "At least 1 citizenship is required"),
      taxDomicileCode: Yup.string().required("Tax domicile is required"),
      countryOfBirthNumber: Yup.number().min(1, "Country of birth is required"),
      email: Yup.string().notRequired().email()
    });

    return (
      <AppSidebarContent contentType={"beneficiary"} isActive={isEditing?.isOpen}>
        <Row className={"w-100"}>
          <Col className={"pb-2"}>
            <Button
              className={"border-0 text-black bg-transparent p-0"}
              style={{ fontSize: "20px" }}
              onClick={() => handleEdit(0, true)}
            >
              {"< " + t("Back")}
            </Button>
          </Col>
          <Col xs={12}>
            <h5 className="AppSidebar-subheader">{t("Add real beneficiary")}</h5>
          </Col>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (value) => {
              const exitingBeneficiaries = formikProps.getFieldProps(name).value;

              if (value.id) {
                await formikProps.setFieldValue(
                  field.name,
                  exitingBeneficiaries.map((beneficiary: DomainEntitiesOmsenDataProposalKycRealBeneficiary) =>
                    beneficiary.id === value.id ? value : beneficiary,
                  ),
                );
              } else {
                const id = uuidv4();
                await formikProps.setFieldValue(field.name, [...exitingBeneficiaries, { ...value, id: id }]);
              }

              handleEdit(0, true);
            }}
          >
            {(formikProps) => (
              <Form autoComplete={"off"} onSubmit={(e: any) => formikProps.handleSubmit(e)}>
                <FormikInput name={"fullName"} title={t("Full Name")} />
                <FormikInput name={"ssn"} title={t("Ssn")} />
                <FormikInput name={"postalAddress"} title={t("Postal address")} />
                <Col>
                  <Row>
                    <FormikInput as={Col} xs={5} name={"postalCode"} title={t("Postal code")} />
                    <FormikInput as={Col} xs={7} name={"city"} title={t("City")} />
                  </Row>
                </Col>
                <FormikCountryPicker name={"countryCode"} title={t("Country")} />
                <FormikLanguagePicker name={"languageCode"} title={t("Language")} />
                <FormikInput name={"phone"} title={t("Phonenumber")}/>
                <FormikInput name={"email"} title={t("Email")}/>
                <FormikInput name={"positionInCompanyInfo"} title={t("Position in company")} />
                <FormikInput name={"shareHoldingInfo"} title={t("Share holding information")} />
                <NaturalPerson />
                <Col className={"pb-2"} xs={12}>
                  <Button type="submit">{t("Save")}</Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
      </AppSidebarContent>
    );
  };

  return (
    <>
      {formikProps.getFieldProps(field.name).value && formikProps.getFieldProps(field.name).value.length > 0 && (
        <Form.Group className={"FieldsInBorder"}>
          {formikProps.getFieldProps(field.name).value.map((beneficiary: any, index: number) => (
            <div key={beneficiary.id}>
              <Row className={"pt-1"}>
                <Col xs={9}>
                  <Form.Label>{beneficiary.fullName}</Form.Label>
                </Col>
                {formikProps.values.omsenState !== OmsenState.ConfirmedOffer && (
                  <Col xs={3} className={"d-flex justify-content-end"}>
                    <EditIcon role={"button"} className={"p-1"} onClick={() => handleEdit(index)} />
                    <DeleteIcon role={"button"} className={"p-1"} onClick={() => deleteBeneficiary(beneficiary.id)} />
                  </Col>
                )}
              </Row>
              <hr className={"my-0 mx-2"} />
            </div>
          ))}
        </Form.Group>
      )}
      <Col>
        <Button
          className={"w-100"}
          style={{ backgroundColor: "#592626" }}
          disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
          onClick={() =>
            handleEdit(
              formikProps.getFieldProps(field.name).value ? formikProps.getFieldProps(field.name).value.length : 0,
            )
          }
        >
          {t("Add real beneficiary")}
        </Button>
      </Col>
      {isEditing.isOpen && sideBarDom && createPortal(addBeneficiary(isEditing.index), sideBarDom)}
    </>
  );
};

export default withTranslation("translations")(Beneficiaries);
