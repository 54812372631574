import {
  IProspectService,
  TBooleanResponse,
  TPaginatedProspectsBaseResponse,
  TProspectBaseResponse,
  TProspectCommentResponse,
  TProspectResponse,
  TSalesAnalyticsResponse,
  TSellerListResponse,
  TStringResponse,
} from "../contracts/services/IProspectService";
import { IApiService } from "../contracts/services/IApiService";
import { IProspectCreate } from "../contracts/data/IProspectCreate";
import { BusinessProfit, BusinessRevenue, IProspect, MaritalStatus } from "../contracts/data/IProspect";
import { IProspectBasicInformation } from "../contracts/data/IProspectBasicInformation";
import * as endpoints from "../constants/endpoints";
import { IProspectBase, ProspectState, SalesState } from "../contracts/data/IProspectBase";
import { IChildDetail } from "../contracts/data/IChildDetail";
import { IInsurance } from "../contracts/data/IInsurance";
import { IInsuranceDetail, InsuranceDetailType } from "../contracts/data/IInsuranceDetail";
import { ISalesAnalyticsData } from "../contracts/data/ISalesAnalyticsData";
import { IProspectHistory } from "../contracts/data/IProspectHistory";
import { IProspectFetch } from "../contracts/data/IProspectFetch";
import { DomainEntitiesProspectOmsenData } from "../api";

type TCreateProspectCommand = {
  firstName: string;
  lastName: string;
  email: string;
  meetingDate: string | null;
  companyCity: string | null;
  companyName: string | null;
  fiscalMonth: number | null;

  companyId: string | null;
  companyAddress: string | null;
  companyPhone: string | null;
  companyContactPerson: string | null;
  companyLanguage: string | null;
  companyZipCode: string | null;
  companyType: string | number | null;
  companyOwnershipPercentage: string | number | null;
  companyOtherOwners: string | null;
  businessIndustryCode: string | null;
  businessEmployeesAmount: string | number | null;
  businessRevenue: BusinessRevenue | null;
  businessProfit: BusinessProfit | null;
  notes: string | null;
};

type TCreateProspectResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  reference: string;
  meetingDate: string | null;
  sellerId: string;
  sellerFirstName: string;
  sellerLastName: string;
  state: ProspectState;
  salesState: SalesState;
  view: string;
  hasProspectFilledInData: boolean;
  isMeetingActive: boolean;
};

type TSendProspectEmailResponse = {};

type TGetProspectEmailResponse = {
  emailContent: string;
};

type TGetProspectByReferenceResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  reference: string;
  meetingDate: string | null;
  sellerId: string;
  sellerFirstName: string;
  sellerLastName: string;
  state: ProspectState;
  salesState: SalesState;
  view: string;
  hasProspectFilledInData: boolean;
  isMeetingActive: boolean;
  companyId: string;
  companyCity: string;
  companyName: string;
  fiscalMonth: number;
  prospectHistories: IProspectHistory[];
};

type TPushProspectViewCommand = {
  view: string;
};

type TPushProspectViewResponse = {};

type TInsuranceDto = {
  id: string;
  type: number;
  isVisible: boolean;
  isTotalsVisible: boolean;
  annualIncomeYel: number | null;
  annualCost: number;
  monthlyCost: number;
  annualCostYel: number;
  monthlyCostYel: number;
  optionalSicknessPayDeductibleDays: number;
  optionalSicknessDuration: number;
  optionalSicknessPayAmount: number | null;
  optionalDisabilityPensionAmount: number | null;
  optionalLifeInsuranceAmount: number | null;
  optionalSeriousIllnessInsuranceAmount: number;
  optionalExpectedNetRate: number;
  optionalOldAgeExtraPensionAnnualCost: number;
  paymentInterval: number;
  details: TInsuranceDetailDto[];
};

type TCreateProspectComment = {
  prospectId: string;
  comment: string;
};

type TCreateProspectCommentResponse = {
  id: string;
  prospectId: string;
  oldValue: string;
  newValue: string;
  modifiedDate: Date;
};

type TInsuranceDetailDto = {
  id: string;
  type: number;
  monthlyAmount: number;
  totalAmount?: number;
  simpleAmount?: number;
  oneOffAmount: number;
  annualCost: number;
  savingYears?: number;
  savingsAtBeginningOfPension?: number;
  isOptional: boolean;
  isSummable: boolean;
  description: string;
  children: TInsuranceDetailDto[];
  examples: TInsuranceExampleDto[] | null;
  omsenProductCode: number | null;
};

type TInsuranceExampleDto = {
  title: string;
  value: number;
  unit: string;
  sortOrder: number;
};

type TChildDetailDto = {
  id: string;
  sortOrder: number;
  age: number | null;
};

type TGetProspectByIdResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  socialSecurityNumber: string | null;
  phoneNumber: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  language: string | null;
  countryOfBirth: string | null;
  citizenship: string | null;
  reference: string;
  meetingDate: string | null;
  sellerId: string;
  sellerFirstName: string;
  sellerLastName: string;
  state: ProspectState;
  salesState: SalesState;
  view: string;
  birthDate: string | null;
  maritalStatus: number | null;
  marriedYear: number | null;
  spouseMonthlySalary: number | null;
  spouseMonthlyPension: number | null;
  spouseBirthDate: string | null;
  childAmount: number | null;
  childDetails: TChildDetailDto[];
  annualIncomeYel: number | null;
  monthlyPension: number | null;
  numberOfYelYears: number | null;
  yelDiscount: number | null;
  optionalLifeInsuranceDiscount: number | null;
  hasProspectFilledInData: boolean;
  assets: number | null;
  notes: string;
  insurances: TInsuranceDto[];
  companyName: string | null;
  companyPhone: string | null;
  companyCity: string | null;
  companyAddress: string | null;
  companyContactPerson: string | null;
  companyEmail: string | null;
  companyId: string | null;
  companyLanguage: string | null;
  companyZipCode: string | null;
  companyType: number | null;
  companyOwnershipPercentage: number | null;
  companyOtherOwners: string | null;
  businessIndustryCode: string | null;
  businessEmployeesAmount: number | null;
  fiscalMonth: number | null;
  businessRevenue: BusinessRevenue | null;
  businessProfit: BusinessProfit | null;
  estimationOfWorth: number | null;
  personalLoans: number | null;
  companyLoans: number | null;
  yearsUntilPension: number | null;
  age: number | null;
  minimumPensionAge: number | null;
  minimumPartialPensionAge: number | null;
  isMeetingActive: boolean;
  salesAnalyticsData: TAnalyticsDto | null;
  prospectHistories: IProspectHistory[];
  omsenData: DomainEntitiesProspectOmsenData | null;
  omsenState: number | null;
};

type TUpdateProspectCommand = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  socialSecurityNumber: string | null;
  phoneNumber: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  language: string | null;
  countryOfBirth: string | null;
  citizenship: string | null;
  meetingDate: string | null;
  birthDate: string | null;
  maritalStatus: number | null;
  marriedYear: number | null;
  spouseMonthlySalary: number | null;
  spouseMonthlyPension: number | null;
  spouseBirthDate: string | null;
  childAmount: number | null;
  childDetails: TChildDetailDto[] | null;
  annualIncomeYel: number | null;
  monthlyPension: number | null;
  numberOfYelYears: number | null;
  yelDiscount: number | null;
  optionalLifeInsuranceDiscount: number | null;
  assets: number | null;
  companyName: string | null;
  companyCity: string | null;
  companyAddress: string | null;
  companyPhone: string | null;
  companyContactPerson: string | null;
  companyEmail: string | null;
  companyId: string | null;
  companyLanguage: string | null;
  companyZipCode: string | null;
  companyType: number | null;
  companyOwnershipPercentage: number | null;
  companyOtherOwners: string | null;
  businessIndustryCode: string | null;
  businessEmployeesAmount: number | null;
  fiscalMonth: number | null;
  businessRevenue: number | null;
  businessProfit: number | null;
  estimationOfWorth: number | null;
  notes: string | null;
  companyLoans: number | null;
  personalLoans: number | null;
  insurances: TInsuranceDto[] | null;
  salesState: SalesState | null;
  sellerId: string | null;
  omsenData: DomainEntitiesProspectOmsenData | null;
  omsenState: number | null;
};

type TUpdateProspectResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  socialSecurityNumber: string | null;
  phoneNumber: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  language: string | null;
  countryOfBirth: string | null;
  citizenship: string | null;
  reference: string;
  meetingDate: string | null;
  sellerId: string;
  sellerFirstName: string;
  sellerLastName: string;
  state: ProspectState;
  salesState: SalesState;
  view: string;
  birthDate: string | null;
  maritalStatus: number | null;
  marriedYear: number | null;
  spouseMonthlySalary: number | null;
  spouseMonthlyPension: number | null;
  spouseBirthDate: string | null;
  childAmount: number | null;
  childDetails: TChildDetailDto[];
  annualIncomeYel: number | null;
  monthlyPension: number | null;
  numberOfYelYears: number | null;
  yelDiscount: number | null;
  optionalLifeInsuranceDiscount: number | null;
  hasProspectFilledInData: boolean;
  assets: number | null;
  notes: string;
  insurances: TInsuranceDto[];
  companyName: string | null;
  companyPhone: string | null;
  companyCity: string | null;
  companyAddress: string | null;
  companyType: number | null;
  companyContactPerson: string | null;
  companyEmail: string | null;
  companyId: string | null;
  companyLanguage: string | null;
  companyZipCode: string | null;
  companyOwnershipPercentage: number | null;
  companyOtherOwners: string | null;
  businessIndustryCode: string | null;
  businessEmployeesAmount: number | null;
  fiscalMonth: number | null;
  businessRevenue: number | null;
  businessProfit: number | null;
  estimationOfWorth: number | null;
  personalLoans: number | null;
  companyLoans: number | null;
  yearsUntilPension: number | null;
  age: number | null;
  minimumPensionAge: number | null;
  minimumPartialPensionAge: number | null;
  isMeetingActive: boolean;
  salesAnalyticsData: TAnalyticsDto | null;
  prospectHistories: IProspectHistory[] | null;
  omsenData: DomainEntitiesProspectOmsenData | null;
  omsenState: number | null;
};

type TUpdateProspectQuestioneerDataCommand = {
  birthDate: string | null;
  maritalStatus: MaritalStatus | null;
  childAmount: number | null;
  annualIncomeYel: number | null;
  monthlyPension: number | null;
};

type TGetPaginatedProspectsResponse = {
  page: number;
  pageSize: number;
  totalAmount: number;
  items: TGetAllProspectsResponse[];
};

type TGetAllProspectsResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  sellerId: string;
  sellerFirstName: string;
  sellerLastName: string;
  reference: string;
  meetingDate: string | null;
  created: string | null;
  state: ProspectState;
  hasProspectFilledInData: boolean;
  view: string;
  isMeetingActive: boolean;
  companyId: string;
  companyCity: string;
  companyName: string;
  fiscalMonth: number;
  salesState: SalesState;
  prospectHistories: IProspectHistory[];
};

type TUpdateProspectQuestioneerDataResponse = {
  state: ProspectState;
  hasProspectFilledInData: boolean;
  view: string;
  isMeetingActive: boolean;
};

type TGetAllSellersResponse = {
  id: string;
  firstName: string;
  lastName: string;
};

type TMeetingDto = {
  id: string | null;
  date: string | null;
  outcome: number | null;
  sortOrder: number;
};

type TSalesDto = {
  id: string | null;
  salesProduct: number;
  price: number;
};

type TPushAnalyticsDataCommand = {
  id: string | null;
  leadChannel: number | null;
  firstMeetingBookingDate: string | null;
  meetings: TMeetingDto[];
  sales: TSalesDto[];
};

type TAnalyticsDto = {
  id: string;
  prospectId: string;
  leadChannel: number;
  firstMeetingBookingDate: string;
  meetings: TMeetingDto[];
  sales: TSalesDto[];
};

export class ProspectService implements IProspectService {
  private apiService: IApiService;

  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async createProspect(prospect: IProspectCreate): Promise<TProspectBaseResponse> {
    const payload: TCreateProspectCommand = {
      email: prospect.email,
      firstName: prospect.firstName,
      lastName: prospect.lastName,
      meetingDate: prospect.meetingDate,
      companyCity: prospect.companyCity,
      companyName: prospect.companyName,
      fiscalMonth: prospect.fiscalMonth,
      companyId: prospect.companyId,
      companyAddress: prospect.companyAddress,
      companyPhone: prospect.companyPhone,
      companyContactPerson: prospect.companyContactPerson,
      companyLanguage: prospect.companyLanguage,
      companyZipCode: prospect.companyZipCode,
      companyType: prospect.companyType,
      companyOwnershipPercentage: prospect.companyOwnershipPercentage,
      companyOtherOwners: prospect.companyOtherOwners,
      businessIndustryCode: prospect.businessIndustryCode,
      businessEmployeesAmount: prospect.businessEmployeesAmount,
      businessRevenue: prospect.businessRevenue,
      businessProfit: prospect.businessProfit,
      notes: prospect.notes,
    };

    const response = await this.apiService.post<TCreateProspectResponse>(endpoints.prospects, payload);

    if (response.data) {
      return {
        data: this.mapCreateProspectResponseToProspectBase(response.data),
      };
    }

    return {
      errors: response.errors,
    };
  }

  async deleteProspect(id: string): Promise<TProspectBaseResponse> {
    const endpoint = endpoints.prospectById.replace(":prospectId", id);
    const endpointResponse = await this.apiService.delete<IProspectBase>(endpoint);
    if (endpointResponse?.data !== undefined) {
      return {
        data: endpointResponse.data,
      };
    } else {
      return {};
    }
  }

  async fetchProspectById(id: string): Promise<TProspectResponse> {
    const endpoint = endpoints.prospectById.replace(":prospectId", id);
    const endpointResponse = await this.apiService.get<TGetProspectByIdResponse>(endpoint);

    if (endpointResponse.data) {
      return {
        data: this.mapGetProspectByIdResponseToProspect(endpointResponse.data),
      };
    }

    return {
      errors: endpointResponse.errors,
    };
  }

  async fetchProspectByReference(reference: string): Promise<TProspectBaseResponse> {
    const endpoint = endpoints.prospectByReference.replace(":prospectReference", reference);
    const endpointResponse = await this.apiService.get<TGetProspectByReferenceResponse>(endpoint);

    if (endpointResponse.data) {
      const result: IProspectBase = {
        email: endpointResponse.data.email,
        firstName: endpointResponse.data.firstName,
        lastName: endpointResponse.data.lastName,
        hasProspectFilledInData: endpointResponse.data.hasProspectFilledInData,
        id: endpointResponse.data.id,
        meetingDate: endpointResponse.data.meetingDate,
        reference: endpointResponse.data.reference,
        sellerId: endpointResponse.data.sellerId,
        sellerFirstName: endpointResponse.data.sellerFirstName,
        sellerLastName: endpointResponse.data.sellerLastName,
        state: endpointResponse.data.state,
        salesState: endpointResponse.data.salesState,
        view: endpointResponse.data.view,
        isMeetingActive: endpointResponse.data.isMeetingActive,
        companyId: endpointResponse.data.companyId,
        companyCity: endpointResponse.data.companyCity,
        companyName: endpointResponse.data.companyName,
        fiscalMonth: endpointResponse.data.fiscalMonth,
        prospectHistories: endpointResponse.data.prospectHistories,
      };
      return { data: result };
    }

    return {
      errors: endpointResponse.errors,
    };
  }

  async fetchProspects(payload: IProspectFetch): Promise<TPaginatedProspectsBaseResponse> {
    let endpoint = endpoints.prospects;
    Object.entries(payload).forEach((entry) => {
      const [key, value] = entry;
      const replaceString = ":" + key.toString();
      endpoint = endpoint.replace(replaceString, value.toString());
    });
    const registerResponse = await this.apiService.get<TGetPaginatedProspectsResponse>(endpoint);

    if (registerResponse.data) {
      return {
        data: registerResponse.data,
      };
    }

    return {
      errors: registerResponse.errors,
    };
  }

  async pushProspectView(id: string, view: string): Promise<TBooleanResponse> {
    const payload: TPushProspectViewCommand = {
      view,
    };

    const endpoint = endpoints.prospectView.replace(":prospectId", id);
    const endpointResponse = await this.apiService.put<TPushProspectViewResponse>(endpoint, payload);

    if (endpointResponse.data) {
      return {
        data: true,
      };
    }

    return {
      errors: endpointResponse.errors,
    };
  }

  async updateProspect(prospectId: string, prospect: Partial<IProspect>): Promise<TProspectResponse> {
    const payload: TUpdateProspectCommand = {
      citizenship: prospect.citizenship ?? null,
      city: prospect.city ?? null,
      countryOfBirth: prospect.countryOfBirth ?? null,
      language: prospect.language ?? null,
      phoneNumber: prospect.phoneNumber ?? null,
      socialSecurityNumber: prospect.socialSecurityNumber ?? null,
      streetAddress: prospect.streetAddress ?? null,
      zipCode: prospect.zipCode ?? null,
      annualIncomeYel: prospect.annualIncomeYel ?? null,
      assets: prospect.assets ?? null,
      birthDate: prospect.birthDate ?? null,
      childAmount: prospect.childAmount ?? null,
      childDetails: prospect.childDetails
        ? prospect.childDetails.map((x) => this.mapChildDetailToChildDetailDto(x))
        : null,
      companyLoans: prospect.companyLoans ?? null,
      companyContactPerson: prospect.companyContactPerson ?? null,
      companyEmail: prospect.companyEmail ?? null,
      companyId: prospect.companyId ?? null,
      companyLanguage: prospect.companyLanguage ?? null,
      companyZipCode: prospect.companyZipCode ?? null,
      companyName: prospect.companyName ?? null,
      companyCity: prospect.companyCity ?? null,
      companyAddress: prospect.companyAddress ?? null,
      companyPhone: prospect.companyPhone ?? null,
      companyOtherOwners: prospect.companyOtherOwners ?? null,
      companyOwnershipPercentage: prospect.companyOwnershipPercentage ?? null,
      companyType: prospect.companyType ?? null,
      businessIndustryCode: prospect.businessIndustryCode ?? null,
      businessEmployeesAmount: prospect.businessEmployeesAmount ?? null,
      fiscalMonth: prospect.fiscalMonth ?? null,
      businessRevenue: prospect.businessRevenue ?? null,
      businessProfit: prospect.businessProfit ?? null,
      email: prospect.email ?? null,
      estimationOfWorth: prospect.estimationOfWorth ?? null,
      firstName: prospect.firstName ?? null,
      maritalStatus: prospect.maritalStatus ?? null,
      lastName: prospect.lastName ?? null,
      marriedYear: prospect.marriedYear ?? null,
      meetingDate: prospect.meetingDate ?? null,
      monthlyPension: prospect.monthlyPension ?? null,
      notes: prospect.notes ?? null,
      numberOfYelYears: prospect.numberOfYelYears ?? null,
      yelDiscount: prospect.yelDiscount ?? null,
      optionalLifeInsuranceDiscount: prospect.optionalLifeInsuranceDiscount ?? null,
      personalLoans: prospect.personalLoans ?? null,
      spouseMonthlyPension: prospect.spouseMonthlyPension ?? null,
      spouseMonthlySalary: prospect.spouseMonthlySalary ?? null,
      spouseBirthDate: prospect.spouseBirthDate ?? null,
      insurances: prospect.insurances ? prospect.insurances.map((x) => this.mapInsuranceToInsuranceDto(x)) : null,
      salesState: prospect.salesState ?? null,
      sellerId: prospect.sellerId ?? null,
      omsenData: prospect.omsenData ?? null,
      omsenState: prospect.omsenState ?? null
    };

    const endpoint = endpoints.prospectById.replace(":prospectId", prospectId);
    const endpointResponse = await this.apiService.put<TUpdateProspectResponse>(endpoint, payload);

    if (endpointResponse.data) {
      return {
        data: this.mapUpdateProspectResponseToProspect(endpointResponse.data),
      };
    }

    return {
      errors: endpointResponse.errors,
    };
  }

  async updateProspectBasicInformation(
    prospect: IProspect,
    basicInformation: IProspectBasicInformation,
  ): Promise<TProspectResponse> {
    const payload: TUpdateProspectQuestioneerDataCommand = {
      annualIncomeYel: basicInformation.annualIncomeYel,
      birthDate: basicInformation.birthDate,
      childAmount: basicInformation.childAmount,
      maritalStatus: basicInformation.maritalStatus,
      monthlyPension: basicInformation.monthlyPension,
    };

    const endpoint = endpoints.prospectQuestioneerData.replace(":prospectId", prospect.id);
    const endpointResponse = await this.apiService.put<TUpdateProspectQuestioneerDataResponse>(endpoint, payload);

    if (endpointResponse.data) {
      return {
        data: {
          ...prospect,
          state: endpointResponse.data.state,
          hasProspectFilledInData: endpointResponse.data.hasProspectFilledInData,
          isMeetingActive: endpointResponse.data.isMeetingActive,
          view: endpointResponse.data.view,
        },
      };
    }

    return {
      errors: endpointResponse.errors,
    };
  }

  async createProspectComment(prospectId: string, comment: string): Promise<TProspectCommentResponse> {
    const payload: TCreateProspectComment = {
      prospectId,
      comment,
    };
    const endpoint = endpoints.prospectHistory.replace(":prospectId", prospectId);
    const registerResponse = await this.apiService.post<TCreateProspectCommentResponse>(endpoint, payload);
    if (registerResponse.data) {
      return {
        data: registerResponse.data,
      };
    }

    return {
      errors: registerResponse.errors,
    };
  }

  async fetchProspectEmail(id: string): Promise<TStringResponse> {
    const endpoint = endpoints.prospectEmail.replace(":prospectId", id);
    const registerResponse = await this.apiService.get<TGetProspectEmailResponse>(endpoint);

    if (registerResponse.data) {
      return {
        data: registerResponse.data.emailContent,
      };
    }

    return {
      errors: registerResponse.errors,
    };
  }

  async sendProspectEmail(id: string): Promise<TBooleanResponse> {
    const endpoint = endpoints.prospectEmail.replace(":prospectId", id);
    const registerResponse = await this.apiService.post<TSendProspectEmailResponse>(endpoint);

    if (registerResponse.errors) {
      return {
        errors: registerResponse.errors,
      };
    }

    return {
      data: true,
    };
  }

  async pushAnalyticsData(salesAnalyticsData: ISalesAnalyticsData): Promise<TSalesAnalyticsResponse> {
    const payload: TPushAnalyticsDataCommand = {
      id: salesAnalyticsData.id,
      leadChannel: salesAnalyticsData.leadChannel,
      firstMeetingBookingDate: salesAnalyticsData.firstMeetingBookingDate,
      meetings: salesAnalyticsData.meetings || [],
      sales: salesAnalyticsData.sales || [],
    };

    const endpoint = endpoints.analyticsData.replace(":prospectId", salesAnalyticsData.prospectId);
    const endpointResponse = await this.apiService.post<TAnalyticsDto>(endpoint, payload);

    if (endpointResponse.data) {
      return {
        data: this.mapAnalyticsDtoToSalesAnalyticsData(endpointResponse.data),
      };
    }

    return {
      errors: endpointResponse.errors,
    };
  }

  async fetchAnalyticsData(prospectId: string): Promise<TSalesAnalyticsResponse> {
    const endpoint = endpoints.analyticsDataByProspectId.replace(":prospectId", prospectId);
    const endpointResponse = await this.apiService.get<TAnalyticsDto>(endpoint);

    if (endpointResponse.data) {
      return {
        data: this.mapAnalyticsDtoToSalesAnalyticsData(endpointResponse.data),
      };
    }

    return {
      errors: endpointResponse.errors,
    };
  }

  async fetchAllSellers(): Promise<TSellerListResponse> {
    const sellersResponse = await this.apiService.get<TGetAllSellersResponse[]>(endpoints.allSellers);

    if (sellersResponse.data) {
      return {
        data: sellersResponse.data,
      };
    }
    return {
      errors: sellersResponse.errors,
    };
  }

  private mapCreateProspectResponseToProspectBase(response: TCreateProspectResponse): IProspectBase {
    return {
      email: response.email,
      firstName: response.firstName,
      id: response.id,
      lastName: response.lastName,
      companyId: null,
      companyName: null,
      companyCity: null,
      fiscalMonth: null,
      meetingDate: response.meetingDate,
      reference: response.reference,
      sellerId: response.sellerId,
      sellerFirstName: response.sellerFirstName,
      sellerLastName: response.sellerLastName,
      state: response.state,
      salesState: response.salesState,
      view: response.view,
      hasProspectFilledInData: response.hasProspectFilledInData,
      isMeetingActive: response.isMeetingActive,
      prospectHistories: null,
    };
  }

  private mapGetProspectByIdResponseToProspect(response: TGetProspectByIdResponse): IProspect {
    return {
      socialSecurityNumber: response.socialSecurityNumber,
      phoneNumber: response.phoneNumber,
      streetAddress: response.streetAddress,
      zipCode: response.zipCode,
      city: response.city,
      language: response.language,
      countryOfBirth: response.countryOfBirth,
      citizenship: response.citizenship,
      companyCity: response.companyCity,
      fiscalMonth: response.fiscalMonth,
      annualIncomeYel: response.annualIncomeYel,
      assets: response.assets,
      birthDate: response.birthDate,
      companyLoans: response.companyLoans,
      companyContactPerson: response.companyContactPerson,
      companyEmail: response.companyEmail,
      companyId: response.companyId,
      companyLanguage: response.companyLanguage,
      companyZipCode: response.companyZipCode,
      companyName: response.companyName,
      companyPhone: response.companyPhone,
      companyAddress: response.companyAddress,
      companyOtherOwners: response.companyOtherOwners,
      companyOwnershipPercentage: response.companyOwnershipPercentage,
      companyType: response.companyType,
      businessIndustryCode: response.businessIndustryCode,
      businessEmployeesAmount: response.businessEmployeesAmount,
      businessRevenue: response.businessRevenue,
      businessProfit: response.businessProfit,
      email: response.email,
      estimationOfWorth: response.estimationOfWorth,
      firstName: response.firstName,
      id: response.id,
      insurances: response.insurances.map((x) => this.mapInsuranceDtoToInsurance(x)),
      maritalStatus: response.maritalStatus,
      lastName: response.lastName,
      marriedYear: response.marriedYear,
      meetingDate: response.meetingDate,
      view: response.view,
      monthlyPension: response.monthlyPension,
      notes: response.notes,
      childAmount: response.childAmount,
      childDetails: response.childDetails.map((x) => this.mapChildDetailDtoToChildDetail(x)),
      numberOfYelYears: response.numberOfYelYears,
      yelDiscount: response.yelDiscount,
      optionalLifeInsuranceDiscount: response.optionalLifeInsuranceDiscount,
      personalLoans: response.personalLoans,
      reference: response.reference,
      sellerId: response.sellerId,
      sellerFirstName: response.sellerFirstName,
      sellerLastName: response.sellerLastName,
      spouseMonthlyPension: response.spouseMonthlyPension,
      spouseMonthlySalary: response.spouseMonthlySalary,
      spouseBirthDate: response.spouseBirthDate,
      state: response.state,
      salesState: response.salesState,
      hasProspectFilledInData: response.hasProspectFilledInData,
      yearsUntilPension: response.yearsUntilPension,
      age: response.age,
      minimumPensionAge: response.minimumPensionAge,
      minimumPartialPensionAge: response.minimumPartialPensionAge,
      isMeetingActive: response.isMeetingActive,
      salesAnalyticsData: response.salesAnalyticsData,
      prospectHistories: response.prospectHistories,
      omsenData: response.omsenData,
      omsenState: response.omsenState,
    };
  }

  private mapUpdateProspectResponseToProspect(response: TUpdateProspectResponse): IProspect {
    return {
      socialSecurityNumber: response.socialSecurityNumber,
      phoneNumber: response.phoneNumber,
      streetAddress: response.streetAddress,
      zipCode: response.zipCode,
      city: response.city,
      language: response.language,
      countryOfBirth: response.countryOfBirth,
      citizenship: response.citizenship,
      companyCity: response.companyCity,
      fiscalMonth: response.fiscalMonth,
      annualIncomeYel: response.annualIncomeYel,
      assets: response.assets,
      birthDate: response.birthDate,
      companyLoans: response.companyLoans,
      companyContactPerson: response.companyContactPerson,
      companyEmail: response.companyEmail,
      companyId: response.companyId,
      companyLanguage: response.companyLanguage,
      companyZipCode: response.companyZipCode,
      companyName: response.companyName,
      companyAddress: response.companyAddress,
      companyPhone: response.companyPhone,
      companyOtherOwners: response.companyOtherOwners,
      companyOwnershipPercentage: response.companyOwnershipPercentage,
      companyType: response.companyType,
      businessIndustryCode: response.businessIndustryCode,
      businessEmployeesAmount: response.businessEmployeesAmount,
      businessRevenue: response.businessRevenue,
      businessProfit: response.businessProfit,
      email: response.email,
      estimationOfWorth: response.estimationOfWorth,
      firstName: response.firstName,
      id: response.id,
      insurances: response.insurances.map((x) => this.mapInsuranceDtoToInsurance(x)),
      maritalStatus: response.maritalStatus,
      lastName: response.lastName,
      marriedYear: response.marriedYear,
      meetingDate: response.meetingDate,
      view: response.view,
      monthlyPension: response.monthlyPension,
      notes: response.notes,
      childAmount: response.childAmount,
      childDetails: response.childDetails.map((x) => this.mapChildDetailDtoToChildDetail(x)),
      numberOfYelYears: response.numberOfYelYears,
      yelDiscount: response.yelDiscount,
      optionalLifeInsuranceDiscount: response.optionalLifeInsuranceDiscount,
      personalLoans: response.personalLoans,
      reference: response.reference,
      sellerId: response.sellerId,
      sellerFirstName: response.sellerFirstName,
      sellerLastName: response.sellerLastName,
      spouseMonthlyPension: response.spouseMonthlyPension,
      spouseMonthlySalary: response.spouseMonthlySalary,
      spouseBirthDate: response.spouseBirthDate,
      state: response.state,
      salesState: response.salesState,
      hasProspectFilledInData: response.hasProspectFilledInData,
      yearsUntilPension: response.yearsUntilPension,
      age: response.age,
      minimumPensionAge: response.minimumPensionAge,
      minimumPartialPensionAge: response.minimumPartialPensionAge,
      isMeetingActive: response.isMeetingActive,
      salesAnalyticsData: response.salesAnalyticsData,
      prospectHistories: response.prospectHistories,
      omsenData: response.omsenData,
      omsenState: response.omsenState,
    };
  }

  private mapChildDetailDtoToChildDetail(dto: TChildDetailDto): IChildDetail {
    return {
      id: dto.id,
      sortOrder: dto.sortOrder,
      age: dto.age,
    };
  }

  private mapChildDetailToChildDetailDto(childDetail: IChildDetail): TChildDetailDto {
    return {
      id: childDetail.id,
      sortOrder: childDetail.sortOrder,
      age: childDetail.age,
    };
  }

  private mapInsuranceDtoToInsurance(dto: TInsuranceDto): IInsurance {
    return {
      annualCost: dto.annualCost,
      annualCostYel: dto.annualCostYel,
      annualIncomeYel: dto.annualIncomeYel,
      details: dto.details
        .map((x) => this.mapInsuranceDetailDtoToInsuranceDetail(x))
        .sort((a, b) => a.sortOrder - b.sortOrder),
      id: dto.id,
      isVisible: dto.isVisible,
      isTotalsVisible: dto.isTotalsVisible,
      monthlyCost: dto.monthlyCost,
      monthlyCostYel: dto.monthlyCostYel,
      optionalSicknessPayAmount: dto.optionalSicknessPayAmount,
      optionalSicknessDuration: dto.optionalSicknessDuration,
      optionalSicknessPayDeductibleDays: dto.optionalSicknessPayDeductibleDays,
      optionalDisabilityPensionAmount: dto.optionalDisabilityPensionAmount,
      optionalLifeInsuranceAmount: dto.optionalLifeInsuranceAmount,
      optionalSeriousIllnessInsuranceAmount: dto.optionalSeriousIllnessInsuranceAmount,
      optionalExpectedNetRate: dto.optionalExpectedNetRate,
      optionalOldAgeExtraPensionAnnualCost: dto.optionalOldAgeExtraPensionAnnualCost,
      paymentInterval: dto.paymentInterval,
      type: dto.type,
    };
  }

  private mapInsuranceToInsuranceDto(insurance: IInsurance): TInsuranceDto {
    return {
      annualCost: insurance.annualCost,
      annualCostYel: insurance.annualCostYel,
      annualIncomeYel: insurance.annualIncomeYel,
      details: insurance.details.map((x) => this.mapInsuranceDetailToInsuranceDetailDto(x)),
      id: insurance.id,
      isVisible: insurance.isVisible,
      isTotalsVisible: insurance.isTotalsVisible,
      monthlyCost: insurance.monthlyCost,
      monthlyCostYel: insurance.monthlyCostYel,
      optionalSicknessPayAmount: insurance.optionalSicknessPayAmount,
      optionalSicknessDuration: insurance.optionalSicknessDuration,
      optionalSicknessPayDeductibleDays: insurance.optionalSicknessPayDeductibleDays,
      optionalDisabilityPensionAmount: insurance.optionalDisabilityPensionAmount,
      optionalLifeInsuranceAmount: insurance.optionalLifeInsuranceAmount,
      optionalSeriousIllnessInsuranceAmount: insurance.optionalSeriousIllnessInsuranceAmount,
      optionalExpectedNetRate: insurance.optionalExpectedNetRate,
      optionalOldAgeExtraPensionAnnualCost: insurance.optionalOldAgeExtraPensionAnnualCost,
      paymentInterval: insurance.paymentInterval,
      type: insurance.type,
    };
  }

  private mapInsuranceDetailToInsuranceDetailDto(insuranceDetail: IInsuranceDetail): TInsuranceDetailDto {
    return {
      annualCost: insuranceDetail.annualCost,
      children: insuranceDetail.children.map((x) => this.mapInsuranceDetailToInsuranceDetailDto(x)),
      description: insuranceDetail.description,
      id: insuranceDetail.id,
      isOptional: insuranceDetail.isOptional,
      isSummable: insuranceDetail.isSummable,
      monthlyAmount: insuranceDetail.monthlyAmount,
      oneOffAmount: insuranceDetail.oneOffAmount,
      type: insuranceDetail.type,
      examples: insuranceDetail.examples,
      omsenProductCode: insuranceDetail.omsenProductCode
    };
  }

  private mapInsuranceDetailDtoToInsuranceDetail(dto: TInsuranceDetailDto): IInsuranceDetail {
    return {
      id: dto.id,
      type: dto.type,
      monthlyAmount: dto.monthlyAmount,
      totalAmount: dto.totalAmount,
      simpleAmount: dto.simpleAmount,
      oneOffAmount: dto.oneOffAmount,
      isOptional: dto.isOptional,
      isSummable: dto.isSummable,
      annualCost: dto.annualCost,
      savingYears: dto.savingYears,
      savingsAtBeginningOfPension: dto.savingsAtBeginningOfPension,
      description: dto.description,
      children: dto.children
        .map((x) => this.mapInsuranceDetailDtoToInsuranceDetail(x))
        .sort((a, b) => a.sortOrder - b.sortOrder),
      sortOrder: this.getInsuranceDetailSortOrder(dto),
      examples: dto.examples,
      omsenProductCode: dto.omsenProductCode
    };
  }

  private mapAnalyticsDtoToSalesAnalyticsData(dto: TAnalyticsDto): ISalesAnalyticsData {
    return {
      id: dto.id,
      prospectId: dto.prospectId,
      leadChannel: dto.leadChannel,
      firstMeetingBookingDate: dto.firstMeetingBookingDate,
      meetings: dto.meetings,
      sales: dto.sales.map((s) => {
        return { ...s, sold: true };
      }),
    };
  }

  private getInsuranceDetailSortOrder(insuranceDetail: TInsuranceDetailDto): number {
    const insuranceDetailType = insuranceDetail.type;
    switch (insuranceDetailType) {
      case InsuranceDetailType.FamilyPension:
        return 300;
      case InsuranceDetailType.FamilyPensionOneOff:
        return 310;
      case InsuranceDetailType.SicknessPay:
        return 100;
      case InsuranceDetailType.SicknessPayCommon:
        return 110;
      case InsuranceDetailType.DisabilityPension:
        return 210;
      case InsuranceDetailType.DisabilityPensionCommon:
        return 200;
      case InsuranceDetailType.PermanentDisabilityInsurance:
        return 220;
      case InsuranceDetailType.OldAgePension:
        return 400;
      case InsuranceDetailType.OldAgeWorkPension:
        return 410;
      case InsuranceDetailType.OldAgeWarrantyPension:
        return 420;
      case InsuranceDetailType.OldAgeCommonPension:
        return 430;
      case InsuranceDetailType.DisabilityOldAgeCommonPension:
        return 431;
      case InsuranceDetailType.OldAgeExtraPension:
        return 440;
      case InsuranceDetailType.LifeInsurance:
        return 340;
      case InsuranceDetailType.FamilyWidowPension:
        return 320;
      case InsuranceDetailType.FamilyChildPension:
        return 330;
      case InsuranceDetailType.SicknessPayOptional:
        return 120;
      case InsuranceDetailType.DisabilityPensionOneOff:
        return 220;
      case InsuranceDetailType.SeriousIllnessOneOff:
        return 500;
      case InsuranceDetailType.OmsenPremium: {
        switch (insuranceDetail.omsenProductCode) {
          case 211:
            return 600;
          case 219:
            return 610;
          case 217:
            return 620;
          case 212:
            return 630;
          default:
            return 640;
        }
      }
    }
    return 0;
  }

  public mapSignalRProspectUpdatedResponseToProspect(data: any): IProspect | null {
    return this.mapGetProspectByIdResponseToProspect(data);
  }
}
