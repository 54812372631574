import React, { useEffect } from "react";
import EmployerWithOccupation from "./EmployerWithOccupation";
import { Button, Form} from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import {
  DomainEntitiesOmsenDataEmployerAnnualEarningsDetail,
} from '../../api';
import {withTranslation} from 'react-i18next';

const FormikEmployersPicker = ({ ...props }: any) => {
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const values = formikProps.getFieldProps(field.name).value
  const { t } = props;

  const addEmployer = async () => {
    await formikProps.setFieldValue(field.name, [...values, {
      fullName: "",
      ssn: "",
      annualEarningsAmount: 0,
      occupationAndEarningPercentages: [],
    }])
  };

  const deleteEmployer = async  (indexToDelete: number) => {
    await formikProps.setFieldValue(field.name, values.filter((occupation: any, index: number) => index !== indexToDelete),)
  }

  useEffect(() => {
    if(values < 1) {
      (async () => {
        await addEmployer();
      })();
    }
  }, []);

  // error can be an object when items in the array has validation errors, if so, we don't want to show it here
  const errorMsg = typeof (formikProps.getFieldMeta(field.name).error as unknown) === "string"
    ? formikProps.getFieldMeta(field.name).error
    : undefined;
  const isInvalid = Boolean(!!errorMsg && formikProps.getFieldMeta(field.name).touched);

  return (
    <Form.Group className={"FormikEmployersPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      {isInvalid && <div className="custom-invalid-feedback">{String(errorMsg)}</div>}
      {values.map(
        (employerEarningDetail: DomainEntitiesOmsenDataEmployerAnnualEarningsDetail, index: number) => (
          <EmployerWithOccupation
            key={index}
            name={`${props.name}.${index}`}
            occupationAndEarningPercentages={employerEarningDetail?.occupationAndEarningPercentages}
            deleteEmployer={() => deleteEmployer(index)}
            disabled={props.disabled}
          />
        ),
      )}
      {!props.disabled && (<div className="mt-2">
        <Button onClick={addEmployer}>{t("Add Employer")}</Button>
      </div>)}
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikEmployersPicker);
