import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { TAppRootState } from "../../redux/types/stateTypes";
import AppSidebarButton from "./AppSidebarButton";
import * as paths from "./../Routes/routePaths";
import AppSidebarContent from "./AppSidebarContent";
import {IProspect, MaritalStatus, OmsenState} from "../../contracts/data/IProspect";
import { Accordion, Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import { dateFormat_DDMMYYYY, dateTimeFormat_YYYYMMDD_HHmmss } from "../../constants/dateFormats";
import { NumericFormat } from "react-number-format";
import questionsIcon from "../../assets/questions_icon.svg";
import noteIcon from "../../assets/note_icon.svg";
import editIcon from "../../assets/edit_customer_icon.svg";
import insuranceIcon from "../../assets/insurance_icon.svg";
import AppSidebarAccordionHeader from "./AppSidebarAccordionHeader";
import ProspectEditNoteForm from "../forms/ProspectEditNoteForm";
import ProspectEditCustomerForm from "../forms/ProspectEditCustomerForm";
import {
  pushAnalyticsData,
  TPushAnalyticsDataAction,
  TUpdateProspectAction,
  updateProspect,
} from "../../redux/actions/prospect";
import ProspectEditInsuranceForm from "../forms/ProspectEditInsuranceForm";
import { getFullName } from "../../utils/appUtils";
import { InsuranceType } from "../../contracts/data/IInsurance";
import ProspectPdfDocumentLink from "../shared/ProspectPdfDocument";
import { ISalesAnalyticsData } from "../../contracts/data/ISalesAnalyticsData";
import CurrentSituationForm from "../forms/questions/CurrentSituationForm";
import DeemedNeedForm from "../forms/questions/DeemedNeedForm";
import BackgroundInformationForm from "../forms/questions/BackgroundInformationForm";
import { ISeller } from "../../contracts/data/ISeller";
import { NavigateFunction, useParams } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import ModalConfirm from "../shared/ModalConfirm";
import OmsenForm from "../forms/questions/OmsenForm";
import OmsenMaintenanceWindow from "../shared/OmsenMaintenanceWindow";
import useOmsenEnabled from "../../hooks/useOmsenEnabled";

type TAppSidebarProps = {
  showSidebarAdminButton: boolean;
  showSidebarUserButton: boolean;
  navigate: NavigateFunction;
  prospectId?: string;
  prospectReference?: string;
  prospectViewName: string;
  prospect: IProspect | null;
  sellers: ISeller[];
  isUpdatingProspect: boolean;
  updateProspect: TUpdateProspectAction;
  pushAnalyticsData: TPushAnalyticsDataAction;
  omsenEnabled?: boolean;
} & WithTranslation;

type TAppSidebarState = {
  isOpen: boolean;
  openContent: string;
  promptMessages: string[];
};

class AppSidebar extends Component<TAppSidebarProps, TAppSidebarState> {
  constructor(props: TAppSidebarProps) {
    super(props);

    this.state = {
      isOpen: false,
      openContent: "",
      promptMessages: [],
    };
  }

  setPromptMessage = (message: string, enabled: boolean) => {
    if (enabled && !this.state.promptMessages.includes(message)) {
      this.setState((state) => ({
        ...state,
        promptMessages: [...state.promptMessages, message],
      }));
    } else if (!enabled && this.state.promptMessages.includes(message)) {
      this.setState((state) => ({
        ...state,
        promptMessages: state.promptMessages.filter((x) => x !== message),
      }));
    }
  };

  getCurrentProspect = (): IProspect | undefined => {
    const { prospectReference, prospectId, prospect } = this.props;
    if (prospect === null) {
      return undefined;
    }

    if (prospectReference !== undefined) {
      return prospect.reference === prospectReference ? prospect : undefined;
    }
    return prospect.id === prospectId ? prospect : undefined;
  };

  handleButtonClick = (buttonType: string): void => {
    if (buttonType === "admin") {
      this.setState(
        (state) => ({
          ...state,
          isOpen: false,
          openContent: "",
        }),
        () => this.props.navigate(paths.prospectsList),
      );
    } else {
      this.setState((state) => ({
        ...state,
        isOpen: !state.isOpen,
        openContent: state.isOpen ? "" : buttonType,
      }));
    }
  };

  renderAdminButton = () => {
    return (
      <AppSidebarButton
        isActive={this.state.openContent === ""}
        buttonType={"admin"}
        onButtonClick={this.handleButtonClick}
      />
    );
  };

  renderUserButton = () => {
    return (
      <AppSidebarButton
        isActive={this.state.openContent === "" || this.state.openContent === "user"}
        buttonType={"user"}
        onButtonClick={this.handleButtonClick}
      />
    );
  };

  renderUserContent = () => {
    const { t } = this.props;
    const prospect = this.getCurrentProspect();

    if (prospect === undefined) {
      return (
        <AppSidebarContent isActive={this.state.openContent === "user"} contentType={"user"}>
          <p>Prospect not available</p>
        </AppSidebarContent>
      );
    }

    const birthDate = moment(prospect.birthDate, dateTimeFormat_YYYYMMDD_HHmmss, true);

    return (
      <AppSidebarContent isActive={this.state.openContent === "user"} contentType={"user"}>
        <>
          <h3 className={"mb-3"}>{getFullName(prospect.firstName, prospect.lastName)}</h3>
          {prospect.birthDate !== null ? (
            <Row>
              <Col className={"mb-2"}>
                <h6>{t("Birthdate")}</h6>
                <h5>{birthDate.isValid() ? birthDate.format(dateFormat_DDMMYYYY) : "-"}</h5>
              </Col>
            </Row>
          ) : null}
          {prospect.maritalStatus !== null || prospect.childAmount !== null ? (
            <>
              <Row>
                {prospect.maritalStatus !== null ? (
                  <Col xs={12} md={6} className={"mb-2"}>
                    <h6>{t("Married")}</h6>
                    <h5>
                      {prospect.maritalStatus === MaritalStatus.Married &&
                      prospect.marriedYear !== null &&
                      prospect.marriedYear > 0
                        ? prospect.marriedYear
                        : prospect.maritalStatus === MaritalStatus.Married
                        ? t("Yes")
                        : t("No")}
                    </h5>
                  </Col>
                ) : null}
                {prospect.childAmount !== null ? (
                  <Col xs={12} md={6} className={"mb-2"}>
                    <h6>{t("Number of children")}</h6>
                    <h5>{prospect.childAmount}</h5>
                  </Col>
                ) : null}
              </Row>
            </>
          ) : null}

          {prospect.annualIncomeYel !== null ? (
            <Row>
              <Col xs={12} md={6} className={"mb-2"}>
                <h6>{t("YEL income")}</h6>
                <h5>
                  <NumericFormat
                    value={Number(prospect.annualIncomeYel)}
                    displayType={"text"}
                    suffix={" €/" + t("year_unit")}
                    thousandSeparator={" "}
                  />
                </h5>
              </Col>
            </Row>
          ) : null}
          {prospect.monthlyPension !== null ? (
            <Row>
              <Col className={"mb-2"}>
                <h6>{t("Accumulated pension to date")}</h6>
                <h5>
                  <NumericFormat
                    value={Number(prospect.monthlyPension)}
                    displayType={"text"}
                    suffix={" €/" + t("month_unit")}
                    thousandSeparator={" "}
                  />
                </h5>
              </Col>
            </Row>
          ) : null}
          <ProspectPdfDocumentLink prospect={prospect} />
        </>
      </AppSidebarContent>
    );
  };

  renderSellerButton = () => {
    return (
      <AppSidebarButton
        isActive={this.state.openContent === "" || this.state.openContent === "seller"}
        buttonType={"seller"}
        onButtonClick={this.handleButtonClick}
      />
    );
  };

  renderSellerContentQuestions = () => {
    const { t } = this.props;

    const prospect = this.getCurrentProspect();

    if (prospect === undefined) {
      return null;
    }

    const insuranceCurrent = prospect.insurances.find((i) => i.type === InsuranceType.Current);
    const insuranceAlternative = prospect.insurances.find((i) => i.type === InsuranceType.Alternative);
    const insuranceOffer = prospect.insurances.find((i) => i.type === InsuranceType.Offer);

    if (insuranceCurrent === undefined || insuranceAlternative === undefined || insuranceOffer === undefined) {
      return (
        <div>
          <p>{t("Missing insurances")}</p>
          <Button
            variant="primary"
            type="button"
            className={"w-100"}
            disabled={this.props.isUpdatingProspect}
            onClick={() => this.props.updateProspect(prospect.id, {})}
          >
            {t("Reload")}
          </Button>
        </div>
      );
    }

    type TQuestionForm = {
      key: string;
      title: string;
      body: any;
      isValid?: boolean;
    };

    const areAllFilledOut = (properties: any[]): boolean => {
      return properties.filter((x) => x === null).length === 0;
    };

    const items: TQuestionForm[] = [];
    items.push({
      key: "currentSituation",
      title: t("Questions affecting current situation"),
      isValid: areAllFilledOut([
        prospect.birthDate,
        prospect.annualIncomeYel,
        prospect.monthlyPension,
        prospect.maritalStatus,
        prospect.childAmount,
        prospect.childDetails.length !== prospect.childAmount ? null : true,
        prospect.maritalStatus !== MaritalStatus.Single || (prospect.childAmount ?? 0) > 0
          ? prospect.maritalStatus !== MaritalStatus.Single ? prospect.spouseBirthDate : true
          : true,
        prospect.maritalStatus !== MaritalStatus.Single ? prospect.spouseMonthlySalary : true,
      ]),
      body: (
        <CurrentSituationForm
          isSubmitting={this.props.isUpdatingProspect}
          prospect={prospect}
          updateProspect={this.props.updateProspect}
          setPromptMessage={this.setPromptMessage}
        />
      ),
    });
    items.push({
      key: "alternativeSituation",
      title: t("Questions affecting the deemed need"),
      isValid: areAllFilledOut([prospect.estimationOfWorth, prospect.personalLoans]),
      body: (
        <DeemedNeedForm
          isSubmitting={this.props.isUpdatingProspect}
          prospect={prospect}
          updateProspect={this.props.updateProspect}
          setPromptMessage={this.setPromptMessage}
        />
      ),
    });
    items.push({
      key: "backgroundInformation",
      title: t("Background information"),
      isValid:
        areAllFilledOut([
          prospect.companyId,
          prospect.companyZipCode,
          prospect.companyLanguage,
          prospect.companyEmail,
          prospect.companyContactPerson,
          prospect.companyName,
          prospect.companyPhone,
          prospect.companyAddress,
          prospect.companyType,
          prospect.companyOwnershipPercentage,
          prospect.companyOtherOwners,
          prospect.businessIndustryCode,
          prospect.businessEmployeesAmount,
          prospect.businessRevenue,
          prospect.businessProfit,
          prospect.salesAnalyticsData?.id,
          prospect.salesAnalyticsData?.leadChannel,
          prospect.salesAnalyticsData?.firstMeetingBookingDate,
        ]) && prospect.salesAnalyticsData?.meetings.every((m) => m.outcome !== null),
      body: (
        <BackgroundInformationForm
          isSubmitting={this.props.isUpdatingProspect}
          prospect={prospect}
          updateProspect={this.props.updateProspect}
          pushAnalyticsData={this.props.pushAnalyticsData}
          setPromptMessage={this.setPromptMessage}
        />
      ),
    });
    if (this.props.omsenEnabled) {
      items.push({
        key: "omsenData",
        title: t("Ömsen"),
        isValid: (prospect.omsenState === OmsenState.ConfirmedOffer && !prospect.omsenData?.errors),
        body: (
          <>
            <OmsenMaintenanceWindow />
            <OmsenForm
              prospect={prospect}
              updateProspect={this.props.updateProspect}
              isSubmitting={this.props.isUpdatingProspect}
            />
          </>
        ),
      });
    }

    const currentInsuranceTitle = (
      <>
        <img src={insuranceIcon} alt={t("Current insurance")} />
        <span>{t("Current insurance")}</span>
      </>
    );
    const comparisonInsuranceTitle = (
      <>
        <img src={insuranceIcon} alt={t("Alternative insurance")} />
        <span>{t("Alternative insurance")}</span>
      </>
    );
    const ourInsuranceTitle = (
      <>
        <img src={insuranceIcon} alt={t("Offer insurance")} />
        <span>{t("Company security")}</span>
      </>
    );

    const insuranceTabs = (
      <div className={"InsuranceTabs"}>
        <Tabs defaultActiveKey={""} id={"seller-content-insurance-tabs"} transition={false} variant={"pills"}>
          <Tab eventKey={"current"} title={currentInsuranceTitle}>
            <ProspectEditInsuranceForm
              isSubmitting={this.props.isUpdatingProspect}
              prospect={prospect}
              insurance={insuranceCurrent}
              updateProspect={this.props.updateProspect}
              promptMessages={this.state.promptMessages}
            />
          </Tab>
          <Tab eventKey={"comparison"} title={comparisonInsuranceTitle}>
            <ProspectEditInsuranceForm
              isSubmitting={this.props.isUpdatingProspect}
              prospect={prospect}
              insurance={insuranceAlternative}
              updateProspect={this.props.updateProspect}
              promptMessages={this.state.promptMessages}
            />
          </Tab>
          <Tab eventKey={"our"} title={ourInsuranceTitle}>
            <ProspectEditInsuranceForm
              isSubmitting={this.props.isUpdatingProspect}
              prospect={prospect}
              insurance={insuranceOffer}
              updateProspect={this.props.updateProspect}
              promptMessages={this.state.promptMessages}
            />
          </Tab>
        </Tabs>
      </div>
    );

    return (
      <>
        <Accordion>
          {items.map((item) => (
            <Card key={item.key}>
              <Card.Header>
                <AppSidebarAccordionHeader eventKey={item.key} validity={item.isValid}>
                  {item.title}
                </AppSidebarAccordionHeader>
              </Card.Header>
              <Accordion.Collapse eventKey={item.key}>
                <Card.Body>{item.body}</Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
        {insuranceTabs}
        <ProspectPdfDocumentLink prospect={prospect} />
      </>
    );
  };

  renderSellerContentEditCustomer = () => {
    const prospect = this.getCurrentProspect();

    if (prospect === undefined) {
      return null;
    }

    return (
      <ProspectEditCustomerForm
        isSubmitting={this.props.isUpdatingProspect}
        prospect={prospect}
        updateProspect={this.props.updateProspect}
        sellers={this.props.sellers}
        setPromptMessage={this.setPromptMessage}
      />
    );
  };

  renderSellerContentNote = () => {
    const prospect = this.getCurrentProspect();

    if (prospect === undefined) {
      return null;
    }

    return (
      <ProspectEditNoteForm
        isSubmitting={this.props.isUpdatingProspect}
        prospect={prospect}
        updateProspect={this.props.updateProspect}
        setPromptMessage={this.setPromptMessage}
      />
    );
  };

  renderSellerContent = () => {
    const { t } = this.props;

    const questionsTitle = (
      <>
        <img src={questionsIcon} alt={t("Questions")} />
        <span>{t("Questions")}</span>
      </>
    );
    const editTitle = (
      <>
        <img src={editIcon} alt={t("Edit customer")} />
        <span>{t("Edit customer")}</span>
      </>
    );
    const noteTitle = (
      <>
        <img src={noteIcon} alt={t("Notes")} />
        <span>{t("Notes")}</span>
      </>
    );

    return (
      <AppSidebarContent isActive={this.state.openContent === "seller"} contentType={"seller"}>
        <Tabs defaultActiveKey={"questions"} id={"seller-content-tabs"} transition={false} variant={"pills"}>
          <Tab eventKey={"questions"} title={questionsTitle}>
            {this.renderSellerContentQuestions()}
          </Tab>
          <Tab eventKey={"editCustomer"} title={editTitle}>
            {this.renderSellerContentEditCustomer()}
          </Tab>
          <Tab eventKey={"note"} title={noteTitle}>
            {this.renderSellerContentNote()}
          </Tab>
        </Tabs>
      </AppSidebarContent>
    );
  };

  render() {
    const { t } = this.props;
    const prospect = this.getCurrentProspect();

    return (
      <>
        <div className={"AppSidebar" + (this.state.isOpen && prospect !== undefined ? " open" : "")}>
          <div className={"AppSidebar-buttons"}>
            {this.props.showSidebarAdminButton && prospect !== undefined ? this.renderSellerButton() : null}
            {this.props.showSidebarUserButton && prospect !== undefined ? this.renderUserButton() : null}
            {this.props.showSidebarAdminButton && prospect !== undefined ? this.renderAdminButton() : null}
          </div>
          <div className={"AppSidebar-contents"} id={"AppSidebar-contents"}>
            {this.props.showSidebarAdminButton && prospect !== undefined ? this.renderSellerContent() : null}
            {this.props.showSidebarUserButton && prospect !== undefined ? this.renderUserContent() : null}
          </div>
        </div>
        {this.props.showSidebarAdminButton && prospect !== undefined ? (
          <ReactRouterPrompt when={this.state.promptMessages.length > 0}>
            {({ isActive, onConfirm, onCancel }) => (
              <ModalConfirm
                show={isActive}
                title={t("Please confirm")}
                bodyHeader=""
                bodyText={t("unsaved changes confirm leave", { where: this.state.promptMessages.join("\n") })}
                onAccept={onConfirm}
                onReject={onCancel}
                yesButtonText={t("Ok")}
                noButtonText={t("Cancel")}
              />
            )}
          </ReactRouterPrompt>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  prospect: state.prospect.prospect,
  sellers: state.prospect.sellerList,
  isUpdatingProspect: state.prospect.isUpdatingProspect,
});

type TMapDispatchToProps = {
  updateProspect: TUpdateProspectAction;
  pushAnalyticsData: TPushAnalyticsDataAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  updateProspect: (prospectId: string, prospect: Partial<IProspect>) => dispatch(updateProspect(prospectId, prospect)),
  pushAnalyticsData: (analyticsData: ISalesAnalyticsData) => dispatch(pushAnalyticsData(analyticsData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withTranslation("translations")((props: TAppSidebarProps) => {
    const { prospectViewName, prospectId } = useParams();
    const { data: omsenEnabled } = useOmsenEnabled();
    return <AppSidebar {...props}
                       prospectViewName={prospectViewName ?? "basic"}
                       prospectId={prospectId}
                       omsenEnabled={omsenEnabled} />;
  }),
);
