export const login = "/api/authentication";
export const refresh = "/api/authentication/refresh";
export const register = "/api/users";
export const prospects = "/api/prospects?sellerId=:sellerId&page=:page&pageSize=:sizePerPage&sortBy=:sortField&sortOrder=:sortOrder&filter=:filter";
export const prospectEmail = "/api/prospects/:prospectId/email";
export const prospectView = "/api/prospects/:prospectId/view";
export const prospectQuestioneerData = "/api/prospects/:prospectId/questioneerData";
export const prospectById = "/api/prospects/:prospectId";
export const prospectByReference = "/api/references/:prospectReference";
export const analyticsData = "/api/analytics/:prospectId/pushsalesdata";
export const analyticsDataByProspectId = "/api/analytics/:prospectId/getsalesdata";
export const prospectHistory = "/api/prospectHistories/:prospectId";
export const allSellers = "/api/sellers";
