import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Formik } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import AlertAutoDismissible from "../shared/AlertAutoDismissible";
import FormikDateTimePicker from "../shared/FormikDateTimePicker";
import * as Yup from "yup";
import { validateEmail } from "../../utils/validatorUtils";
import { IProspectCreate } from "../../contracts/data/IProspectCreate";
import * as paths from "../Routes/routePaths";
import { IProspectBase } from "../../contracts/data/IProspectBase";
import { BusinessProfit, BusinessRevenue, IProspect } from "../../contracts/data/IProspect";
import FormikCompanyTypePicker from "../shared/FormikCompanyTypePicker";
import FormikInput from "../shared/FormikInput";
import { IsCodeValid, TOLCodes } from "../../constants/industryCodes";
import { getValidProfitValues, getValidRevenueValues } from "../../utils/appUtils";
import { NavigateFunction } from "react-router-dom";
import FormikSelect from '../shared/FormikSelect';

type TProspectCreateFormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  companyCity: string;
  fiscalMonth: number | null;
  email: string;
  meetingDate: string;

  companyId: string;
  companyAddress: string;
  companyPhone: string;
  companyContactPerson: string;
  companyLanguage: string;
  companyZipCode: string;
  companyType: string | number | null;
  companyOwnershipPercentage: string | number | null;
  companyOtherOwners: string;
  businessIndustryCode: string;
  businessEmployeesAmount: string | number;
  businessRevenue: BusinessRevenue | null;
  businessProfit: BusinessProfit | null;
  notes: string;
};

type TProspectCreateFormProps = {
  isSubmitting: boolean;
  createProspect(prospect: IProspectCreate): Promise<IProspectBase | null>;
  navigate: NavigateFunction;
  copyProspect?: IProspect;
} & WithTranslation;

type TProspectCreateFormState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
  inputTolCode: string;
};

class ProspectCreateForm extends Component<TProspectCreateFormProps, TProspectCreateFormState> {
  constructor(props: TProspectCreateFormProps) {
    super(props);

    this.state = {
      submitResultMessage: "",
      submitResultSuccess: true,
      inputTolCode: this.props.copyProspect?.businessIndustryCode ?? "",
    };
  }

  render() {
    const { t, copyProspect } = this.props;

    const minDate = new Date(2022,0,1)
    const maxDate = new Date(2029,11,31)

    const initialValues: TProspectCreateFormValues = {
      firstName: copyProspect?.firstName ?? "",
      lastName: copyProspect?.lastName ?? "",
      companyName: copyProspect?.companyName ?? "",
      companyCity: copyProspect?.companyCity ?? "",
      fiscalMonth: copyProspect?.fiscalMonth ?? 12,
      email: copyProspect?.email ?? "",
      meetingDate: copyProspect?.meetingDate ?? "",

      companyId: copyProspect?.companyId ?? "",
      companyAddress: copyProspect?.companyAddress ?? "",
      companyPhone: copyProspect?.companyPhone ?? "",
      companyContactPerson: copyProspect?.companyContactPerson ?? "",
      companyLanguage: copyProspect?.companyLanguage ?? "",
      companyZipCode: copyProspect?.companyZipCode ?? "",
      companyType: copyProspect?.companyType ?? "",
      companyOwnershipPercentage: copyProspect?.companyOwnershipPercentage ?? "",
      companyOtherOwners: copyProspect?.companyOtherOwners ?? "",
      businessIndustryCode: copyProspect?.businessIndustryCode ?? "",
      businessEmployeesAmount: copyProspect?.businessEmployeesAmount ?? "",
      businessRevenue: copyProspect?.businessRevenue ?? null,
      businessProfit: copyProspect?.businessProfit ?? null,
      notes: copyProspect?.notes ?? "",
    };

    const validationSchemaCreate = Yup.object().shape({
      firstName: Yup.string().required(t("Required")),
      lastName: Yup.string().required(t("Required")),
      email: Yup.string().test("test-email", t("Invalid email address"), validateEmail),
      meetingDate: Yup.string().required(t("Required")),
      companyCity: Yup.string().notRequired(),
      companyName: Yup.string().notRequired(),
      fiscalMonth: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().min(1, t("More than 0")).max(12, t("Less than 13"));
        }
        return Yup.mixed();
      }),
    });

    const validationSchemaCopy = Yup.object().shape({
      firstName: Yup.string().required(t("Required")),
      lastName: Yup.string().required(t("Required")),
      email: Yup.string().test("test-email", t("Invalid email address"), validateEmail),
      meetingDate: Yup.string().required(t("Required")),
      companyCity: Yup.string().notRequired(),
      companyName: Yup.string().notRequired(),
      fiscalMonth: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().min(1, t("More than 0")).max(12, t("Less than 13"));
        }
        return Yup.mixed();
      }),

      companyId: Yup.string().notRequired(),
      companyContactPerson: Yup.string().notRequired(),
      companyPhone: Yup.string().notRequired(),
      companyLanguage: Yup.string().notRequired(),
      companyAddress: Yup.string().notRequired(),
      companyZipCode: Yup.string().notRequired(),
      companyType: Yup.mixed().notRequired(),
      companyOwnershipPercentage: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().nullable().min(0, t("Must be positive")).max(100, t("Cannot be higher than 100")).notRequired();
        }
        return Yup.mixed();
      }),
      businessIndustryCode: Yup.lazy((value) => {
        if (value !== null && value !== undefined) {
          return Yup.mixed().test("isValidTolCode", t("Invalid tol code"), () => IsCodeValid(value)).notRequired();
        }
        return Yup.mixed();
      }),
      businessEmployeesAmount: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().min(1, t("Must be positive")).notRequired();
        }
        return Yup.mixed();
      }),
      businessRevenue: Yup.mixed().notRequired(),
      businessProfit: Yup.mixed().notRequired(),
      notes: Yup.string().notRequired(),
    });

    const validationSchema = copyProspect? validationSchemaCopy: validationSchemaCreate;

    const emptyStringToNull = (stringToNull: any) => {
      if (stringToNull === "") return null;
      else return stringToNull;
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          this.setState((state) => ({
            ...state,
            submitResultSuccess: true,
            submitResultMessage: "",
          }));

          const prospect: IProspectCreate = {
            email: "",
            firstName: "",
            lastName: "",
            companyName: "",
            companyCity: "",
            fiscalMonth: null,
            meetingDate: "",

            companyId: "",
            companyAddress: "",
            companyPhone: "",
            companyContactPerson: "",
            companyLanguage: "",
            companyZipCode: "",
            companyType: null,
            companyOwnershipPercentage: null,
            companyOtherOwners: "",
            businessIndustryCode: "",
            businessEmployeesAmount: null,
            businessRevenue: null,
            businessProfit: null,
            notes: "",
          };

          values.fiscalMonth = emptyStringToNull(values.fiscalMonth);
          values.companyType = emptyStringToNull(values.companyType);
          values.companyOwnershipPercentage = emptyStringToNull(values.companyOwnershipPercentage);
          values.businessEmployeesAmount = emptyStringToNull(values.businessEmployeesAmount);
          values.businessRevenue = emptyStringToNull(values.businessRevenue);
          values.businessProfit = emptyStringToNull(values.businessProfit);

          const result = await this.props.createProspect({ ...prospect, ...values });
          if (result !== null) {
            this.setState(
              (state) => ({
                ...state,
                submitResultSuccess: true,
                submitResultMessage: t("Successfully created"),
              }),
              () => {
                this.props.navigate(paths.prospectCreated.replace(":prospectId", result.id));
              },
            );
          } else {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: false,
              submitResultMessage: t("Unable to create"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete={"off"} noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <Row>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Firstname")}</Form.Label>
                <Form.Control
                  name={"firstName"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.firstName}
                  isInvalid={!!formikProps.errors.firstName && formikProps.touched.firstName}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.firstName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Lastname")}</Form.Label>
                <Form.Control
                  name={"lastName"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.lastName}
                  isInvalid={!!formikProps.errors.lastName && formikProps.touched.lastName}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.lastName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Email")}</Form.Label>
                <Form.Control
                  name={"email"}
                  type={"email"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.email}
                  isInvalid={!!formikProps.errors.email && formikProps.touched.email}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Meeting")}</Form.Label>
                <FormikDateTimePicker name={"meetingDate"} minDate={minDate} maxDate={maxDate}/>
                <Form.Control.Feedback type="invalid">{formikProps.errors.meetingDate}</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Company name")}</Form.Label>
                <Form.Control
                  name={"companyName"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyName}
                  isInvalid={!!formikProps.errors.companyName && formikProps.touched.companyName}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Company city")}</Form.Label>
                <Form.Control
                  name={"companyCity"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyCity}
                  isInvalid={!!formikProps.errors.companyCity && formikProps.touched.companyCity}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyCity}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Fiscal month")}</Form.Label>
                <Form.Control
                  name={"fiscalMonth"}
                  type={"number"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.fiscalMonth ?? ""}
                  isInvalid={!!formikProps.errors.fiscalMonth && formikProps.touched.fiscalMonth}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.fiscalMonth}</Form.Control.Feedback>
              </Form.Group>
            </Row>

            {copyProspect? (
            <>
            <Row className="mt-3">
            <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Company id")}</Form.Label>
                <Form.Control
                  name={"companyId"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyId}
                  isInvalid={!!formikProps.errors.companyId && formikProps.touched.companyId}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyId}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Company contact person")}</Form.Label>
                <Form.Control
                  name={"companyContactPerson"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyContactPerson}
                  isInvalid={!!formikProps.errors.companyContactPerson && formikProps.touched.companyContactPerson}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyContactPerson}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Company phone")}</Form.Label>
                <Form.Control
                  name={"companyPhone"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyPhone}
                  isInvalid={!!formikProps.errors.companyPhone && formikProps.touched.companyPhone}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyPhone}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Company language")}</Form.Label>
                <Form.Control
                  name={"companyLanguage"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyLanguage}
                  isInvalid={!!formikProps.errors.companyLanguage && formikProps.touched.companyLanguage}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyLanguage}</Form.Control.Feedback>
              </Form.Group>
              </Row>

              <Row>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Company address")}</Form.Label>
                <Form.Control
                  name={"companyAddress"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyAddress}
                  isInvalid={!!formikProps.errors.companyAddress && formikProps.touched.companyAddress}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyAddress}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Company zip code")}</Form.Label>
                <Form.Control
                  name={"companyZipCode"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyZipCode}
                  isInvalid={!!formikProps.errors.companyZipCode && formikProps.touched.companyZipCode}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyZipCode}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <FormikCompanyTypePicker name={"companyType"} title={t("Company type")} emptyOption={true} />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyCity}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <FormikInput
                  name={"companyOwnershipPercentage"}
                  title={t("Company ownership")}
                  type={"number"}
                  unit={"%"}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyOwnershipPercentage}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>

              <Form.Group className='form-group'  as={Col} xs={12} md={6} lg={3}>
              <Form.Label>{t("Industry code")}</Form.Label>
              <Form.Control
                className="text-uppercase"
                name={"businessIndustryCode"}
                type={"text"}
                onChange={(e) => {
                  formikProps.handleChange(e);
                  this.setState((state) => ({ ...state, inputTolCode: e.target.value }));
                }}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.businessIndustryCode || ""}
                isInvalid={!!formikProps.errors.businessIndustryCode && formikProps.touched.businessIndustryCode}
              />
              {this.state.inputTolCode && (
                <div className="tol-code-value w-100 ">{TOLCodes(this.state.inputTolCode) || ""}</div>
              )}
              <Form.Control.Feedback type="invalid">{formikProps.errors.businessIndustryCode}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
              <FormikInput type={"number"} name={"businessEmployeesAmount"} title={t("Number of employees")} />
              <Form.Control.Feedback type="invalid">{formikProps.errors.businessEmployeesAmount}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
              <FormikSelect options={getValidRevenueValues()} name={"businessRevenue"} title={t("Business revenue")}/>
            </Form.Group>
            <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
              <FormikSelect options={getValidProfitValues()} name={"businessProfit"} title={t("Business profit")}/>
            </Form.Group>
            </Row>

            <Row>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Other company owners")}</Form.Label>
                <Form.Control
                  name={"companyOtherOwners"}
                  as={"textarea"}
                  rows={3}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.companyOtherOwners || ""}
                  isInvalid={!!formikProps.errors.companyOtherOwners && formikProps.touched.companyOtherOwners}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.companyOtherOwners}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='form-group' as={Col} xs={12} md={6} lg={3}>
                <Form.Label>{t("Notes")}</Form.Label>
                <Form.Control
                  name={"notes"}
                  as={"textarea"}
                  rows={3}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.notes || ""}
                  isInvalid={!!formikProps.errors.notes && formikProps.touched.notes}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.notes}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            </>) : null}

            <Form.Group className='form-group'>
              <Button className={"btn-create-form"} variant="primary" type="submit" disabled={this.props.isSubmitting}>
                {this.props.isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                  </>
                ) : null}
                {t("Create")}
              </Button>
              {this.state.submitResultMessage ? (
                <AlertAutoDismissible
                  variant={this.state.submitResultSuccess ? "success" : "danger"}
                  className={"mt-2"}
                >
                  {this.state.submitResultMessage}
                </AlertAutoDismissible>
              ) : null}
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(ProspectCreateForm);
