import { IInsurance } from "./IInsurance";
import { IProspectBase } from "./IProspectBase";
import { IChildDetail } from "./IChildDetail";
import { ISalesAnalyticsData } from "./ISalesAnalyticsData";
import { DomainEntitiesProspectOmsenData } from "../../api";

export enum BusinessRevenue {
  Tier1 = 1,
  Tier2,
  Tier3,
  Tier4,
  Tier5,
}

export enum BusinessProfit {
  Tier1 = 1,
  Tier2,
  Tier3,
  Tier4,
  Tier5,
}

export enum MaritalStatus {
  Married = 1,
  RegisteredPartnership = 2,
  Single = 3,
}

export enum OmsenState {
  PremiumCalculation = 1,
  PendingOffer = 2,
  ConfirmedOffer = 3,
}

export interface IProspect extends IProspectBase {
  phoneNumber: string | null;
  socialSecurityNumber: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  language: string | null;
  countryOfBirth: string | null;
  citizenship: string | null;
  companyCity: string | null;
  fiscalMonth: number | null;
  companyId: string | null;
  companyName: string | null;
  companyZipCode: string | null;
  companyAddress: string | null;
  companyEmail: string | null;
  companyLanguage: string | null;
  companyContactPerson: string | null;
  companyPhone: string | null;
  companyType: number | null;
  companyOwnershipPercentage: number | null;
  companyOtherOwners: string | null;

  businessIndustryCode: string | null;
  businessEmployeesAmount: number | null;
  businessRevenue: BusinessRevenue | null;
  businessProfit: BusinessProfit | null;

  birthDate: string | null;
  maritalStatus: MaritalStatus | null;
  marriedYear: number | null;
  spouseMonthlySalary: number | null;
  spouseMonthlyPension: number | null;
  spouseBirthDate: string | null;
  childAmount: number | null;
  childDetails: IChildDetail[];
  annualIncomeYel: number | null;
  monthlyPension: number | null;
  numberOfYelYears: number | null;
  yelDiscount: number | null;
  optionalLifeInsuranceDiscount: number | null;
  estimationOfWorth: number | null;
  companyLoans: number | null;
  personalLoans: number | null;

  assets: number | null;
  notes: string;

  insurances: IInsurance[];

  yearsUntilPension: number | null;
  age: number | null;
  minimumPensionAge: number | null;
  minimumPartialPensionAge: number | null;

  salesAnalyticsData: ISalesAnalyticsData | null;

  omsenData: DomainEntitiesProspectOmsenData | null;
  omsenState: number | null;
}
