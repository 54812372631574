import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {Formik, FormikProps} from "formik";
import { DomainEntitiesProspectOmsenData } from "../../../api";
import { IProspect, OmsenState } from "../../../contracts/data/IProspect";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import FormikProductPicker from "../../shared/FormikProductPicker";
import FormikOccupation from "../../shared/FormikOccupation";
import FormikEmployersPicker from "../../shared/FormikEmployersPicker";
import * as Yup from "yup";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";
import { TUpdateProspectAction } from "../../../redux/actions/prospect";
import FormikIndustrialClassificationPicker from "../../shared/FormikIndustrialClassificationPicker";
import FormikInput from "../../shared/FormikInput";
import FormikCountryPicker from "../../shared/FormikCountryPicker";
import FormikLanguagePicker from "../../shared/FormikLanguagePicker";
import FormikCustomerCategoryPicker from "../../shared/FormikCustomerCatergoryPicker";
import NaturalPerson from "../../shared/NaturalPerson";
import LegalPerson from "../../shared/LegalPerson";
import ModalConfirm from "../../shared/ModalConfirm";
import { InsuranceType } from "../../../contracts/data/IInsurance";

const createEmptyOmsenDataObject = (prospect?: IProspect): DomainEntitiesProspectOmsenData => ({
  industrialClassificationCode: null,
  productCodes: [],
  employeePayrollDetails: [],
  employerAnnualEarningsDetails: [],
  proposal: {
    invoiceReceiverName: null,
    claimReceiverName: null,
    internalNote: null,
    policyHolder: {
      customerCategoryCode: null,
      fullName: prospect?.firstName ?? null,
      ssn: prospect?.socialSecurityNumber ?? null,
      postalAddress: prospect?.streetAddress ?? null,
      postalCode: prospect?.zipCode ?? null,
      city: prospect?.city ?? null,
      countryCode: null,
      languageCode: null,
      phone: prospect?.phoneNumber ?? null,
      mobile: null,
      email: prospect?.email ?? null,
      kycNaturalPerson: {
        taxDomicileCode: null,
        countryOfBirthNumber: 0,
        citizenshipNumbers: [],
        politicalExposedPerson: false,
      },
      kycLegalPerson: {
        industrialClassificationCode: null,
        countryOfRegistrationNumber: 0,
        countriesOfTaxationNumbers: [],
        contactPersonName: null,
        contactPersonPhone: null,
        contactPersonEmail: null,
        realBeneficiaries: [],
      },
    },
  },
  errors: null,
});

export type TFormValues = {
  omsenData: DomainEntitiesProspectOmsenData | null;
  omsenState: number | null;
};

type TProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  updateProspect: TUpdateProspectAction;
} & WithTranslation;

type TSubmitState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
};
const OmsenForm: React.FC<TProps> = ({ prospect, t, updateProspect, isSubmitting }) => {
  const [submitState, setSubmitState] = useState<TSubmitState>({ submitResultMessage: "", submitResultSuccess: true });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: TFormValues = {
    omsenData: prospect.omsenData ?? createEmptyOmsenDataObject(prospect),
    omsenState: prospect.omsenState,
  };

  const getOmsenInsurance = () => {
    const insuranceDetails = prospect.insurances.find((insurance) => insurance.type === InsuranceType.Omsen)?.details;
    if (insuranceDetails && insuranceDetails?.length > 0) return true;
  };

  const validationSchema = Yup.object().shape({
    omsenState: Yup.number().notRequired(),
    omsenData: Yup.object().when("omsenState", ([omsenState], schema) => {
      const premiumCostCalculationObject = schema.shape({
        productCodes: Yup.array().notRequired(),
        industrialClassificationCode: Yup.number().when("productCodes", ([productCodes], schema) => {
          if (productCodes.length > 0) return schema.required("Industrial classification is a required");
          return schema.notRequired();
        }),
        employeePayrollDetails: Yup.array().when("productCodes", ([productCodes], schema) => {
          if (productCodes?.includes(211)) {
            return schema.min(1, t("At least one occupation is required")).of(
              Yup.object().shape({
                totalPayrollAmount: Yup.number()
                  .required(t("Required"))
                  .min(1, t("Total payroll must be greater than 0")),
              }),
            );
          }
          return schema;
        }),
        employerAnnualEarningsDetails: Yup.array().when("productCodes", ([productCodes], schema) => {
          if (productCodes?.includes(212)) {
            return schema
              .min(1, t("At least one employer is required"))
              .max(3, t("A maximum of three employers is allowed"))
              .of(
                Yup.object().shape({
                  fullName: Yup.mixed().required(t("Full name required!")),
                  ssn: Yup.string().required(t("Ssn required!")),
                  annualEarningsAmount: Yup.number().min(1, t("Must be greater than 0")),
                  occupationAndEarningPercentages: Yup.array()
                    .of(
                      Yup.object().shape({
                        percentageOfAnnualEarnings: Yup.number()
                          .required(t("Required"))
                          .min(1, t("Must be greater than 0"))
                          .max(100, t("Must be less than 100")),
                      }),
                    )
                    .min(1, t("At least one occupation is required"))
                    .test("sum-of-percentage", t("The sum of the percentages must be 100"), (values) => {
                      const sum =
                        values?.reduce((acc, value) => {
                          return acc + value.percentageOfAnnualEarnings;
                        }, 0) || 0;
                      return sum === 100;
                    }),
                }),
              );
          }
          return schema;
        }),
      });
      if (omsenState === OmsenState.PendingOffer) {
        return premiumCostCalculationObject.shape({
          proposal: Yup.object().shape({
            invoiceReceiverName: Yup.string().required("Invoice receiver name is required"),
            claimReceiverName: Yup.string().required("Claim receiver name is required"),
            policyHolder: Yup.object().shape({
              customerCategoryCode: Yup.string().required("Customer category is required"),
              fullName: Yup.string().required("Name is required"),
              ssn: Yup.string().required("Business ID/Ssn is required"),
              postalAddress: Yup.string().required("Postal address is required"),
              postalCode: Yup.string().required("Postal code is required"),
              city: Yup.string().required("City is required"),
              countryCode: Yup.string().required("County is required"),
              languageCode: Yup.string().required("Language is required"),
              kycNaturalPerson: Yup.object().when("customerCategoryCode", ([customerCategoryCode], schema) => {
                if (["EF", "Private"].includes(customerCategoryCode)) {
                  return schema.shape({
                    taxDomicileCode: Yup.string().required("Tax domicile is required"),
                    countryOfBirthNumber: Yup.number().min(1, "Country of birth is required"),
                    citizenshipNumbers: Yup.array().of(Yup.number()).min(1, "At least 1 citizenship is required"),
                  });
                }
                return schema;
              }),
              kycLegalPerson: Yup.object().when("customerCategoryCode", ([customerCategoryCode], schema) => {
                if (["ÖB", "AB", "KB"].includes(customerCategoryCode)) {
                  return schema.shape({
                    industrialClassificationCode: Yup.string().required("Industrial classification is a required"),
                    countryOfRegistrationNumber: Yup.number().min(1, "Country of registration is required"),
                    countriesOfTaxationNumbers: Yup.array()
                      .of(Yup.number())
                      .min(1, "At least 1 taxation country is required"),
                    contactPersonName: Yup.string().required("Company contact (name) is required"),
                  });
                }
                return schema;
              }),
            }),
          }),
        });
      }
      return premiumCostCalculationObject;
    }),
  });

  const handleSubmit = async (values: TFormValues) => {
    setSubmitState({ submitResultSuccess: true, submitResultMessage: "" });
    if (values.omsenData) {
      values.omsenData.errors = "";
    }

    const result = await updateProspect(prospect.id, { ...values });

    if (result !== null && !result?.omsenData?.errors) {
      setSubmitState({ submitResultSuccess: true, submitResultMessage: t("Successfully updated") });
    } else {
      if (result?.omsenData?.errors) {
        setSubmitState({
          submitResultSuccess: false,
          submitResultMessage: `${t("Unable to update")}: ${result?.omsenData?.errors}`,
        });
      } else {
        setSubmitState({ submitResultSuccess: false, submitResultMessage: t("Unable to update") });
      }
    }

    handleClose();
  };

  const openProposalForm = async (formikProps: FormikProps<TFormValues>) => {
    await formikProps.setFieldValue("omsenState", OmsenState.PendingOffer)
    if(prospect.firstName && prospect.lastName){
      await formikProps.setFieldValue("omsenData.proposal.invoiceReceiverName", prospect.firstName + " " + prospect.lastName)
      await formikProps.setFieldValue("omsenData.proposal.claimReceiverName", prospect.firstName + " " + prospect.lastName)
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await handleSubmit(values);
      }}
    >
      {(formikProps) => (
        <Form autoComplete={"off"} onSubmit={(e: any) => formikProps.handleSubmit(e)}>
          <h5 className="AppSidebar-subheader">{t("Premium Calculation")}</h5>
          <FormikIndustrialClassificationPicker
            name={"omsenData.industrialClassificationCode"}
            title={t("Industrial classification")}
            disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
          />
          <FormikProductPicker
            name={"omsenData.productCodes"}
            title={t("Products")}
            disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
          />
          {formikProps.values.omsenData?.productCodes?.includes(211) && (
            <FormikOccupation
              name={"omsenData.employeePayrollDetails"}
              title={t("Occupation")}
              disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
            />
          )}
          {formikProps.values.omsenData?.productCodes?.includes(212) && (
            <FormikEmployersPicker
              name={"omsenData.employerAnnualEarningsDetails"}
              title={t("Employers")}
              disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
            />
          )}
          {formikProps.values.omsenState === OmsenState.PremiumCalculation && getOmsenInsurance() && (
            <Col className={"pb-4"}>
              <Button
                className={"w-100"}
                onClick={() => openProposalForm(formikProps)}
              >
                {t("Create insurance proposal")}
              </Button>
            </Col>
          )}
          {formikProps.values.omsenState !== OmsenState.PremiumCalculation && (
            <>
              <h5 className="AppSidebar-subheader">{t("Proposal")}</h5>
              <FormikInput
                name={"omsenData.proposal.invoiceReceiverName"}
                title={t("Invoice receiver name")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              <FormikInput
                name={"omsenData.proposal.claimReceiverName"}
                title={t("Claim receiver name")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              <Form.Group className="form-group">
                <Form.Label>{t("Internal note")}</Form.Label>
                <Form.Control
                  name={"omsenData.proposal.internalNote"}
                  as={"textarea"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.omsenData?.proposal?.internalNote || ""}
                  disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                />
              </Form.Group>
              <FormikCustomerCategoryPicker
                name={"omsenData.proposal.policyHolder.customerCategoryCode"}
                title={t("Customer category")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              <FormikInput
                name={"omsenData.proposal.policyHolder.fullName"}
                title={t("Full Name")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              <FormikInput
                name={"omsenData.proposal.policyHolder.ssn"}
                title={
                  ["EF", "Private"].includes(
                    formikProps.getFieldProps("omsenData.proposal.policyHolder.customerCategoryCode").value,
                  )
                    ? t("Ssn")
                    : t("Business ID")
                }
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              <FormikInput
                name={"omsenData.proposal.policyHolder.postalAddress"}
                title={t("Postal address")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              <Row>
                <FormikInput
                  as={Col}
                  xs={5}
                  name={"omsenData.proposal.policyHolder.postalCode"}
                  title={t("Postal code")}
                  disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                />
                <FormikInput
                  as={Col}
                  xs={7}
                  name={"omsenData.proposal.policyHolder.city"}
                  title={t("City")}
                  value={prospect.companyCity}
                  disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                />
              </Row>
              <FormikCountryPicker
                name={"omsenData.proposal.policyHolder.countryCode"}
                title={t("Country")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              <FormikLanguagePicker
                name={"omsenData.proposal.policyHolder.languageCode"}
                title={t("Language")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              {["EF", "Private"].includes(
                formikProps.getFieldProps("omsenData.proposal.policyHolder.customerCategoryCode").value,
              ) && <NaturalPerson name={"omsenData.proposal.policyHolder.kycNaturalPerson"} />}
              {["ÖB", "AB", "KB"].includes(
                formikProps.getFieldProps("omsenData.proposal.policyHolder.customerCategoryCode").value,
              ) && (
                <LegalPerson name={"omsenData.proposal.policyHolder.kycLegalPerson"} prospect={prospect.omsenData} />
              )}
            </>
          )}
          <Form.Group className="form-group">
            <Button
              variant="primary"
              type="submit"
              className={"w-100"}
              disabled={isSubmitting || formikProps.values.omsenState === OmsenState.ConfirmedOffer}
            >
              {isSubmitting && (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                </>
              )}
              {t("Save")}
            </Button>
            {formikProps.values.omsenState === OmsenState.PendingOffer &&
              !formikProps.errors.omsenData &&
              !formikProps.values.omsenData?.errors && (
                <Button className={"w-100 mt-2"} onClick={handleShow} disabled={isSubmitting}>
                  {t("Confirm insurance proposal")}
                </Button>
              )}
            {submitState.submitResultMessage && (
              <AlertAutoDismissible variant={submitState.submitResultSuccess ? "success" : "danger"} className={"mt-2"}>
                {submitState.submitResultMessage}
              </AlertAutoDismissible>
            )}
          </Form.Group>
          <ModalConfirm
            show={show}
            title={t("Confirm insurance proposal")}
            bodyText={t("When you confirm you can't modify Ömsen data anymore!")}
            onAccept={async () => {
              const newValues = {
                ...formikProps.values,
                omsenState: OmsenState.ConfirmedOffer,
              };
              await handleSubmit(newValues);
            }}
            onReject={handleClose}
            yesButtonText={t("Confirm")}
            noButtonText={t("Cancel")}
          />
        </Form>
      )}
    </Formik>
  );
};

export default withTranslation("translations")(OmsenForm);
