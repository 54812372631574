import { IInsuranceDetail, InsuranceDetailType } from "../../../contracts/data/IInsuranceDetail";
import { NumericFormat } from "react-number-format";
import InfoHoverBox from "../../shared/InfoHoverBox";
import { generateRandomId } from "../../../utils/appUtils";
import { IInsurance, InsuranceType } from "../../../contracts/data/IInsurance";
import { Col, Row } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import useOmsenProducts from "../../../hooks/useOmsenProducts";
import getInsuranceDetailSummaryTitle from "./utils/getInsuranceDetailSummaryTitle";

type TProspectInsuranceDetailSummaryRowsProps = {
  currentInsuranceId: string;
  insurance: IInsurance;
  rows: IInsuranceDetail[];
  parentRow?: IInsuranceDetail;
  readonly: boolean;
  navigateToInsuranceDetailView(insuranceId: string, insuranceDetailId: string, push?: boolean): any;
};

function ProspectInsuranceDetailSummaryRows(props: TProspectInsuranceDetailSummaryRowsProps) {
  const { currentInsuranceId, insurance, rows, parentRow, navigateToInsuranceDetailView, readonly } = props;
  const { t } = useTranslation("translations");
  const { data: omsenProducts } = useOmsenProducts();

  const createInsuranceRow = (
    id: string,
    title: string,
    value: number,
    isOneOff: boolean,
    description: string | JSX.Element,
    subRows: IInsuranceDetail[] = [],
    decimalScale: number = 0,
    unit: string = "€",
  ): {
    subRows: IInsuranceDetail[];
    isOneOff: boolean;
    description: string | JSX.Element;
    id: string;
    title: string;
    value: JSX.Element;
    unit: string;
  } => {
    return {
      id,
      title,
      value: (
        <NumericFormat value={value} displayType={"text"} thousandSeparator={" "} decimalScale={decimalScale || 0} />
      ),
      isOneOff,
      subRows,
      description,
      unit,
    };
  };

  const colRows = rows
    .map((row) => {
      switch (row.type) {
        case InsuranceDetailType.FamilyPensionOneOff:
        case InsuranceDetailType.DisabilityPensionOneOff:
        case InsuranceDetailType.SeriousIllnessOneOff:
          if (row.isOptional && row.oneOffAmount <= 0) {
            return null;
          }
          return createInsuranceRow(
            row.id,
            getInsuranceDetailSummaryTitle({ t, insuranceDetail: row, omsenProducts }),
            row.oneOffAmount,
            true,
            row.description,
            row.children,
          );
        case InsuranceDetailType.FamilyWidowPension:
          return createInsuranceRow(
            row.id,
            getInsuranceDetailSummaryTitle({ t, insuranceDetail: row, omsenProducts }),
            row.monthlyAmount,
            true,
            <InfoHoverBox infoText={t("Family widow pension help text")} totalAmount={row.totalAmount || 0} />,
            row.children,
          );
        case InsuranceDetailType.FamilyChildPension:
          return createInsuranceRow(
            row.id,
            getInsuranceDetailSummaryTitle({ t, insuranceDetail: row, omsenProducts }),
            row.monthlyAmount || 0,
            true,
            <InfoHoverBox infoText={t("Family child pension help text")} totalAmount={row.totalAmount || 0} />,
            row.children,
          );
        case InsuranceDetailType.FamilyPension:
        case InsuranceDetailType.DisabilityPension:
        case InsuranceDetailType.DisabilityPensionCommon:
        case InsuranceDetailType.OldAgePension:
        case InsuranceDetailType.DisabilityOldAgeCommonPension:
        case InsuranceDetailType.OldAgeCommonPension:
        case InsuranceDetailType.OldAgeExtraPension:
        case InsuranceDetailType.OldAgeWorkPension:
        case InsuranceDetailType.OldAgeWarrantyPension:
        case InsuranceDetailType.SicknessPay:
        case InsuranceDetailType.SicknessPayCommon:
        case InsuranceDetailType.SicknessPayOptional:
        case InsuranceDetailType.LifeInsurance:
          // There are cases when the monthly amount can't be calculated, but we still
          // have a oneOff amount. In that case we show the one having a value.
          if (row.monthlyAmount === 0 && row.oneOffAmount > 0) {
            return createInsuranceRow(
              row.id,
              getInsuranceDetailSummaryTitle({ t, insuranceDetail: row, omsenProducts }),
              row.oneOffAmount,
              true,
              row.description,
              row.children,
            );
          }
        case InsuranceDetailType.PermanentDisabilityInsurance:
          return createInsuranceRow(
            row.id,
            getInsuranceDetailSummaryTitle({ t, insuranceDetail: row, omsenProducts }),
            row.monthlyAmount,
            false,
            row.description,
            row.children,
          );
        case InsuranceDetailType.OmsenPremium:
          return createInsuranceRow(
            row.id,
            getInsuranceDetailSummaryTitle({ t, insuranceDetail: row, omsenProducts }),
            row.annualCost,
            false,
            "",
            [],
            0,
            "€"
          );
        default:
          return null;
      }
    })
    .filter((x) => x !== null);

  const showOneOffPayments = (insuranceDetail: IInsuranceDetail) => {
    // sickness pay doesn't have a one off amount, so we don't want to render a 0€ one off total amount.
    return insuranceDetail.type !== InsuranceDetailType.SicknessPay;
  };

  const numberWithThousandSeparator = (number: number) =>
    Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (parentRow !== undefined) {
    if (parentRow.type === InsuranceDetailType.FamilyPension) {
      colRows.push(
        createInsuranceRow(
          generateRandomId(),
          t("Total family pension for widow and children"),
          parentRow.monthlyAmount,
          true,
          t("Total until eol - description", { amount: numberWithThousandSeparator(parentRow.totalAmount || 0) }),
        ),
      );
    }
    if (parentRow.type === InsuranceDetailType.OldAgeExtraPension && insurance.type === InsuranceType.Offer) {
      colRows.push(createInsuranceRow(generateRandomId(), t("Yearly payment"), parentRow.annualCost, true, ""));
      colRows.push(
        createInsuranceRow(generateRandomId(), t("Saving time"), parentRow.savingYears || 0, true, "", [], 2, "v"),
      );
      colRows.push(
        createInsuranceRow(
          generateRandomId(),
          t("Total payments"),
          parentRow.annualCost * (parentRow.savingYears || 0),
          true,
          "",
        ),
      );
      colRows.push(
        createInsuranceRow(
          generateRandomId(),
          t("Savings at the beginning of pension"),
          parentRow.savingsAtBeginningOfPension || 0,
          true,
          "",
        ),
      );
      colRows.push(createInsuranceRow(generateRandomId(), t("Total pension"), parentRow.totalAmount || 0, true, ""));
      colRows.push(
        createInsuranceRow(generateRandomId(), t("Old age pension extra"), parentRow.monthlyAmount, false, ""),
      );
    } else if (parentRow.type !== InsuranceDetailType.FamilyPension) {
      colRows.push(
        createInsuranceRow(
          generateRandomId(),
          t("Total"),
          parentRow.monthlyAmount,
          false,
          t("Amount until end of life", {
            amount: numberWithThousandSeparator(parentRow.totalAmount || 0),
          }),
        ),
      );
      if (showOneOffPayments(parentRow) && parentRow.children.length === 0) {
        colRows.push(createInsuranceRow(generateRandomId(), t("Total (one-off)"), parentRow.oneOffAmount, true, ""));
      }
    }
  }

  let counter = 0;
  return (<>
    {colRows.map((insuranceRow, index) => {
    if (insuranceRow === null) {
      return null;
    }

    let clickCallback: any = null;

    const hasSubView =
      !readonly &&
      (insuranceRow.description || insuranceRow.subRows.length > 0) &&
      insuranceRow.description !== t("Total until eol - description");
    if (hasSubView) {
      clickCallback = () => navigateToInsuranceDetailView(currentInsuranceId, insuranceRow.id);
    }
    const descriptionCol = hasSubView ? (
      <Col key={`${insuranceRow.id}_1`} className={"details"} style={{ display: "flex", alignItems: "center" }}>
        <Row className={"description"}>
          {typeof insuranceRow.description === "string" ? (
            insuranceRow.description.split("\n").map((row) => <span>{row}</span>)
          ) : (
            <span>{insuranceRow.description}</span>
          )}
        </Row>
      </Col>
    ) : null;

    const rowClass = insuranceRow.isOneOff ? "one-off " : "monthly ";
    const unit = `${insuranceRow.unit}${(insuranceRow.isOneOff || insurance.type === InsuranceType.Omsen) ? "" : "/" + t("month_unit")}`;
    const xsSpan = insuranceRow.isOneOff ? 4 : 5;
    const widerFirstCol = (parentRow?.children?.length ?? 0) > 0;
    const colWidth = widerFirstCol ? 7 : 6;
    const labelWidth = widerFirstCol ? 6 : 5;
    let colSeparator = null;
    if ((parentRow?.children?.length ?? 0) > 0 || (counter > 0 && counter % 2 === 0)) {
      colSeparator = <div key={`${insuranceRow.id}_separator`} className={"separator w-100"} />;
    }

    counter += hasSubView ? 2 : 1;

    return (
      <Row key={insuranceRow.id}>
        {colSeparator}
        <Col key={`${insuranceRow.id}_0`} xs={12} md={colWidth}>
          <Row
            className={`details ${rowClass} ${clickCallback !== null ? " clickable" : ""}`}
            onClick={() => (clickCallback !== null ? clickCallback() : null)}
          >
            <Col className={"details-label"} xs={7} md={labelWidth} lg={labelWidth}>
              <span>{insuranceRow.title}</span>
            </Col>
            <Col className={"details-value text-end me-2"} xs={xsSpan} md={5}>
              {insuranceRow.value}
              <span className={"value-suffix"}>{unit}</span>
            </Col>
          </Row>
        </Col>
        {descriptionCol}
      </Row>
    );
  }, counter)}
  </>);
}

export default ProspectInsuranceDetailSummaryRows;
