import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ErrorMessage, useField, useFormikContext } from "formik";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { dateFormat_YYYYMMMDD, dateTimeFormat_YYYYMMDD_HHmmss } from "../../constants/dateFormats";

type TDateParts = {
  day: string;
  month: string;
  year: string;
};

const getMomentFromDateParts = (dateParts: TDateParts) => {
  return moment(dateParts.year + "-" + dateParts.month + "-" + dateParts.day, dateFormat_YYYYMMMDD, true);
};

const getMomentFromDateString = (date: string) => {
  return moment(date, dateTimeFormat_YYYYMMDD_HHmmss, true);
};

const getPartialDate = (date: string, part: "day" | "month" | "year") => {
  const parsedDate = getMomentFromDateString(date);
  if (parsedDate.isValid()) {
    switch (part) {
      case "day":
        return parsedDate.format("DD");
      case "month":
        return parsedDate.format("MM");
      case "year":
        return parsedDate.format("YYYY");
    }
  }

  return "";
};

const FormikDatePicker = ({ ...props }: any) => {
  const { t } = props;
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const [dateParts, setDateParts] = useState<TDateParts>({
    day: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    setDateParts({
      day: getPartialDate(field.value, "day"),
      month: getPartialDate(field.value, "month"),
      year: getPartialDate(field.value, "year"),
    });
    return () => {
      setDateParts({
        day: "",
        month: "",
        year: "",
      });
    };
  }, [field.value]);

  const getValidYears = () => {
    const currentYear = Number(moment().format("YYYY"));
    const firstValidYear = 1920;
    const result = [];

    for (let i = firstValidYear; i <= currentYear; i++) {
      const str = String(i);
      result.push([str, str]);
    }

    return result.reverse();
  };

  const getValidMonths = () => {
    return [
      ["01", t("January")],
      ["02", t("February")],
      ["03", t("March")],
      ["04", t("April")],
      ["05", t("May")],
      ["06", t("June")],
      ["07", t("July")],
      ["08", t("August")],
      ["09", t("September")],
      ["10", t("October")],
      ["11", t("November")],
      ["12", t("December")],
    ];
  };

  const getValidDays = () => {
    const result = [];

    for (let i = 1; i <= 31; i++) {
      const str = String(i).padStart(2, "0");
      result.push([str, str]);
    }

    return result;
  };

  const handleChange = (e: any, part: "day" | "month" | "year") => {
    const value = e.currentTarget.value;
    const updateDateParts = {
      ...dateParts,
      [part]: value,
    };

    setDateParts(updateDateParts);

    const newDate = getMomentFromDateParts(updateDateParts);
    if (newDate.isValid()) {
      formikProps.setFieldTouched(field.name, true, false);
      formikProps.setFieldError(field.name, "");
      formikProps.setFieldValue(field.name, newDate.format(dateTimeFormat_YYYYMMDD_HHmmss));
    } else {
      formikProps.setFieldTouched(field.name, true, false);
      formikProps.setFieldError(field.name, t("Invalid date"));
    }
  };

  const dayFieldName = field.name + "_day";
  const monthFieldName = field.name + "_month";
  const yearFieldName = field.name + "_year";

  const isCurrentDateInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);
  const isSingleRow = props.singleRow !== undefined ? props.singleRow : false;

  const dayFormField = (
    <Form.Control
      name={dayFieldName}
      as={"select"}
      value={dateParts.day}
      onChange={(e) => handleChange(e, "day")}
      isInvalid={isCurrentDateInvalid}
    >
      <option value={""}></option>
      {getValidDays().map((x) => (
        <option value={x[0]} key={x[0]}>
          {x[1]}
        </option>
      ))}
    </Form.Control>
  );

  const monthFormField = (
    <Form.Control
      name={monthFieldName}
      as={"select"}
      value={dateParts.month}
      onChange={(e) => handleChange(e, "month")}
      isInvalid={isCurrentDateInvalid}
    >
      <option value={""}></option>
      {getValidMonths().map((x) => (
        <option value={x[0]} key={x[0]}>
          {x[1]}
        </option>
      ))}
    </Form.Control>
  );

  const yearFormField = (
    <Form.Control
      name={yearFieldName}
      as={"select"}
      value={dateParts.year}
      onChange={(e) => handleChange(e, "year")}
      isInvalid={isCurrentDateInvalid}
    >
      <option value={""}></option>
      {getValidYears().map((x) => (
        <option value={x[0]} key={x[0]}>
          {x[1]}
        </option>
      ))}
    </Form.Control>
  );

  return (
    <div className={"FormikDatePicker"}>
      {props.title !== undefined ? (
        <Row>
          <Form.Group as={Col} className={"mb-0 form-group"}>
            <Form.Label>{props.title}</Form.Label>
          </Form.Group>
        </Row>
      ) : null}
      {!isSingleRow ? (
        <>
          <Row>
            <Form.Group as={Col} xs={3} className={"mb-0 form-group"}>
              {dayFormField}
            </Form.Group>
            <Form.Group as={Col} xs={9} className={"mb-0 form-group"}>
              {monthFormField}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className={"mt-2 form-group"}>
              {yearFormField}
              {props.nested ? (
                <ErrorMessage name={field.name} component={"div"} className="custom-invalid-feedback" />
              ) : (
                <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
        </>
      ) : (
        <>
          <Row className={"mb-2"}>
            <Form.Group as={Col} xs={4} sm={3} md={4} lg={3} className={"mt-2 mb-1 form-group"}>
              {dayFormField}
            </Form.Group>
            <Form.Group as={Col} xs={8} sm={6} md={8} lg={6} className={"mt-2 mb-1 form-group"}>
              {monthFormField}
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              sm={3}
              md={12}
              lg={3}
              className={"mt-2 mb-1 form-group" + (isCurrentDateInvalid ? " is-invalid" : "")}
            >
              {yearFormField}
            </Form.Group>
            {props.nested ? (
              <ErrorMessage name={props.name} component={Form.Control.Feedback} className="custom-invalid-feedback" />
            ) : (
              <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default withTranslation("translations")(FormikDatePicker);
