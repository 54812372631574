import React from "react";
import { Form } from "react-bootstrap";
import useOmsenOccupations from "../../hooks/useOmsenOccupations";
import { useField, useFormikContext } from "formik";

const FormikOccupationPicker = ({ ...props }: any) => {
  const { data: occupations, isLoading, isError } = useOmsenOccupations();
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const handleSelectChange = (e: any) => {
    if (props.onSelectChange) {
      props.onSelectChange(e.target.value);
    }
  };

  const filteredOccupations = occupations?.filter(
    (o) => !props.exitingOccupations?.some((exiting: any) => exiting.occupationCode === o.code),
  );

  const occupationText = () => {
    const occupation = (occupations ?? []).find((occupation) => occupation.code === props.occupationOption);

    if (occupation) {
      return <Form.Label column>{occupation.text}</Form.Label>;
    }
    return <Form.Label>No occupation found</Form.Label>;
  };

  // error can be an object when items in the array has validation errors, if so, we don't want to show it here
  const errorMsg = typeof (formikProps.getFieldMeta(field.name).error as unknown) === "string"
    ? formikProps.getFieldMeta(field.name).error
    : undefined;
  const isInvalid = Boolean(!!errorMsg && formikProps.getFieldMeta(field.name).touched);

  return (
    <Form.Group>
      {props.occupationText ? (
        occupationText()
      ) : (
        <Form.Select
          as={"select"}
          value={props.occupationOption || undefined}
          onChange={handleSelectChange}
          size="lg"
          isInvalid={isInvalid}
        >
          {field.value === 0 || field.value === "" || field.value === null || props.emptyOption ? (
            <option value={""} key={"-"}></option>
          ) : null}
          <option value="" />
          {isLoading && (
            <option value={""} key={"loading"}>
              Loading...
            </option>
          )}
          {!isLoading && isError && (
            <option value={""} key={"error"}>
              Error loading languages
            </option>
          )}
          {!!filteredOccupations && (
            filteredOccupations.map((occupation) => {
              if (occupation.code === null) return null;
              return (
                <option value={occupation.code} key={occupation.code}>
                  {occupation.code} - {occupation.text}
                </option>
              );
            })
          )}
        </Form.Select>
      )}
      <Form.Control.Feedback type="invalid">
        {String(errorMsg)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormikOccupationPicker;
