import {IInsurance, InsuranceType} from "../../../contracts/data/IInsurance";
import {useTranslation} from "react-i18next";
import useOmsenProducts from "../../../hooks/useOmsenProducts";
import {IInsuranceDetail, InsuranceDetailType} from "../../../contracts/data/IInsuranceDetail";
import {NumericFormat} from "react-number-format";
import {Col, Row} from "react-bootstrap";
import React from "react";

type TProspectInsuranceSummaryRowsProps = {
  insurance: IInsurance;
  readonly: boolean;
  navigateToInsuranceDetailView(insuranceId: string, insuranceDetailId: string, push?: boolean): any;
};

function ProspectInsuranceSummaryRows(props: TProspectInsuranceSummaryRowsProps) {
  const { insurance, navigateToInsuranceDetailView, readonly } = props;
  const { t } = useTranslation("translations");
  const { data: omsenProducts } = useOmsenProducts();

  const isInsuranceOfType = (types: InsuranceType[]) => {
    return types.findIndex((t) => t === insurance.type) > -1;
  };

  type TInsuranceRowType = {
    title: string;
    value: any;
    isOneOff: boolean;
    id: string;
    isOptional: boolean;
    description: string;
    subRows: IInsuranceDetail[];
    unit?: string;
  };

  const createInsuranceRow = (
    id: string,
    title: string,
    value: number,
    isOneOff: boolean,
    isOptional: boolean,
    description: string,
    subRows: IInsuranceDetail[] = [],
    unit?: string,
  ): TInsuranceRowType => {
    return {
      id,
      title,
      value: <NumericFormat value={value} displayType={"text"} thousandSeparator={" "} decimalScale={0}/>,
      isOneOff,
      isOptional,
      description,
      subRows,
      unit,
    };
  };

  const getInsuranceDetailTitle = (insuranceDetail: IInsuranceDetail): string => {
    switch (insuranceDetail.type) {
      case InsuranceDetailType.DisabilityPension:
        return t("Disability pension");
      case InsuranceDetailType.DisabilityPensionOneOff:
        return t("Disability pension one-off payment");
      case InsuranceDetailType.FamilyPension:
        return t("Family pension");
      case InsuranceDetailType.FamilyPensionOneOff:
        return t("Family pension one-off payment");
      case InsuranceDetailType.OldAgePension:
        return t("Old age pension");
      case InsuranceDetailType.SicknessPay:
        return t("Sickness benefit");
      case InsuranceDetailType.SeriousIllnessOneOff:
        return t("Serious illness one-off payment");
      case InsuranceDetailType.LifeInsurance:
        return t("Life insurance");
      case InsuranceDetailType.OmsenPremium:
        return omsenProducts?.find(x => x.code === insuranceDetail.omsenProductCode)?.text ?? "";
      default:
        return String(insuranceDetail.type);
    }
  };

  const getInsuranceDetailTotalRow = (row: TInsuranceRowType) => {
    const className = "details one-off my-1 optional";
    let title;
    let value;
    let value_suffix;

    insurance.details.forEach((detail) => {
      if (detail.id === row.id) {
        switch (detail.type) {
          case InsuranceDetailType.DisabilityPension:
            title = t("Disability pension one-off payment");
            value = detail.oneOffAmount;
            value_suffix = "€";
            break;
          case InsuranceDetailType.FamilyPension:
            title = t("Family pension one-off payment");
            value = detail.oneOffAmount;
            value_suffix = "€";
            break;
          case InsuranceDetailType.OldAgePension:
            detail.children.forEach((child) => {
              if (child.type === InsuranceDetailType.OldAgeExtraPension) {
                title = t("Old age pension one-off payment");
                value = child.monthlyAmount;
                value_suffix = "€/" + t("month_unit");
              }
            });
            break;
          case InsuranceDetailType.SicknessPay:
            title = t("Sickness benefit daily payment");
            value = insurance.optionalSicknessPayAmount;
            value_suffix = "€/" + t("day_unit");
            break;
        }
      }
    });
    if (title && value && value_suffix)
      return (
        <Row className={className} key={row.id + "-dt"}>
          <Col className={"details-label"} xs={9} md={8}>
            <span>{title}</span>
          </Col>
          <Col className={"details-value text-end"} xs={3} md={4}>
            <NumericFormat value={value} displayType={"text"} thousandSeparator={" "} decimalScale={0}/>
            <span className={"value-suffix"}> {value_suffix}</span>
          </Col>
        </Row>
      );
  };

  return (<>
    {insurance.details
      .map((row) => {
        switch (row.type) {
          case InsuranceDetailType.SeriousIllnessOneOff:
            if (row.isOptional && row.oneOffAmount <= 0) {
              return null;
            } else if (isInsuranceOfType([InsuranceType.Offer])) {
              return createInsuranceRow(
                row.id,
                getInsuranceDetailTitle(row),
                row.oneOffAmount,
                false,
                false, //force render as root (non optional) detail
                row.description,
                row.children,
                '€',
              );
            }
          case InsuranceDetailType.FamilyPensionOneOff:
          case InsuranceDetailType.DisabilityPensionOneOff:
            if (row.isOptional && row.oneOffAmount <= 0) {
              return null;
            }
            return createInsuranceRow(
              row.id,
              getInsuranceDetailTitle(row),
              row.oneOffAmount,
              true,
              row.isOptional,
              row.description,
              row.children,
            );
          case InsuranceDetailType.FamilyPension:
          case InsuranceDetailType.DisabilityPension:
          case InsuranceDetailType.LifeInsurance:
          case InsuranceDetailType.OldAgePension:
          case InsuranceDetailType.SicknessPay:
            return createInsuranceRow(
              row.id,
              getInsuranceDetailTitle(row),
              row.monthlyAmount,
              false,
              row.isOptional,
              row.description,
              row.children,
            );
          case InsuranceDetailType.OmsenPremium:
            return createInsuranceRow(
              row.id,
              getInsuranceDetailTitle(row),
              row.annualCost,
              false,
              row.isOptional,
              row.description,
              [],
              "€"
            );
          default:
            return null;
        }
      })
      .filter((x) => x !== null)
      .map((insuranceRow) => {
        if (insuranceRow === null) {
          return null;
        }

        let clickCallback: null | (() => void) = null;

        if (!readonly && (insuranceRow.description || insuranceRow.subRows.length > 0)) {
          clickCallback = () => navigateToInsuranceDetailView(insurance.id, insuranceRow.id);
        }

        if (!insuranceRow.isOneOff) {
          const className =
            "details monthly my-1" +
            (insuranceRow.isOptional ? " optional" : "") +
            (clickCallback !== null ? " clickable" : "");

          return (
            <div key={insuranceRow.id}>
              <Row
                className={className}
                onClick={() => (clickCallback !== null ? clickCallback() : null)}
                key={insuranceRow.id}
              >
                <Col className={"details-label d-flex"} xs={9} md={8}>
                  <span className="align-self-end">{insuranceRow.title}</span>
                </Col>
                <Col className={"details-value text-end"} xs={3} md={4}>
                  {insuranceRow.value}
                  <span className={"value-suffix"}>{insuranceRow.unit ?? "€/" + t("month_unit")}</span>
                </Col>
              </Row>
              {insurance.isTotalsVisible && isInsuranceOfType([InsuranceType.Offer])
                ? getInsuranceDetailTotalRow(insuranceRow)
                : null}
            </div>
          );
        } else {
          const className =
            "details one-off my-1" +
            (insuranceRow.isOptional ? " optional" : "") +
            (clickCallback !== null ? " clickable" : "");
          return (
            <div key={insuranceRow.id}>
              <Row
                className={className}
                onClick={() => (clickCallback !== null ? clickCallback() : null)}
                key={insuranceRow.id}
              >
                <Col className={"details-label"} xs={9} md={8}>
                  <span>{insuranceRow.title}</span>
                </Col>
                <Col className={"details-value text-end"} xs={3} md={4}>
                  {insuranceRow.value}
                  <span className={"value-suffix"}> €</span>
                </Col>
              </Row>
              {insurance.isTotalsVisible && isInsuranceOfType([InsuranceType.Offer])
                ? getInsuranceDetailTotalRow(insuranceRow)
                : null}
            </div>
          );
        }
      })}
  </>);
}

export default ProspectInsuranceSummaryRows;
