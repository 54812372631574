import React, { Component } from "react";
import { TAppRootState } from "../../../redux/types/stateTypes";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  fetchProspectByReference,
  TFetchProspectByReferenceAction,
  updateProspectSuccess,
} from "../../../redux/actions/prospect";
import { IProspect } from "../../../contracts/data/IProspect";
import { Spinner } from "react-bootstrap";
import ProspectView from "./ProspectView";
import { signalRHubListenProspect, TSignalRHubListenProspectAction } from "../../../redux/actions/signalRHub";
import * as paths from "../../Routes/routePaths";
import { Navigate, NavigateFunction, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

type TProspectPresentationProps = {
  navigate: NavigateFunction;
  pathname: string;
  queryParams: URLSearchParams
  prospectReference?: string;
  prospect: IProspect | null;
  isFetchingProspect: boolean;
  fetchProspectByReference: TFetchProspectByReferenceAction;
  updateProspectSuccess: (prospect: IProspect) => any;
  signalRHubListenProspect: TSignalRHubListenProspectAction;
} & WithTranslation;

type TProspectPresentationState = {};

class ProspectPresentation extends Component<TProspectPresentationProps, TProspectPresentationState> {
  constructor(props: TProspectPresentationProps) {
    super(props);

    this.handleStorageUpdate = this.handleStorageUpdate.bind(this);
  }

  handleStorageUpdate(e: StorageEvent) {
    // TODO: This can be removed once we get SignalR up and running
    const storageKey = e.key;
    if (storageKey === "insurance_calculator_mock_prospects") {
      const jsonString = localStorage.getItem(storageKey);
      if (jsonString !== null) {
        const storedProspects: IProspect[] = JSON.parse(jsonString);
        const updatedProspect = storedProspects.find((p) => p.reference === this.props.prospectReference);
        if (updatedProspect !== undefined) {
          this.props.updateProspectSuccess(updatedProspect);
        }
      }
    }
  }

  async componentDidMount() {
    if (this.props.prospectReference !== undefined) {
      await this.props.fetchProspectByReference(this.props.prospectReference);
      await this.props.signalRHubListenProspect(this.props.prospectReference);
    }

    window.addEventListener("storage", this.handleStorageUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorageUpdate);
  }

  render() {
    if (this.props.isFetchingProspect) {
      return (
        <div className="ProspectMeeting">
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
        </div>
      );
    }

    const prospect = this.props.prospect;

    if (prospect === undefined || prospect === null) {
      return <div className="ProspectMeeting">No meeting found</div>;
    }

    else if (!prospect.isMeetingActive) {
      const redirectPath = paths.meetingBasicInformation.replace(":prospectReference", prospect.reference);
      return <Navigate replace to={redirectPath} />;
    }

    const urlWithQueryParams = prospect.view ? prospect.view.split("?") : [];
    const urlParts = urlWithQueryParams.length >= 1 ? urlWithQueryParams[0].split("/") : [];

    const prospectViewName = urlParts.length >= 1 ? urlParts[0] : undefined;
    const insuranceId = urlParts.length >= 2 ? urlParts[1] : undefined;
    const insuranceDetailId = urlParts.length >= 3 ? urlParts[2] : undefined;

    const queryParams = urlWithQueryParams.length >= 2 ? new URLSearchParams(urlWithQueryParams[1]) : undefined;
    const summaryViewName = queryParams?.get("summary") ?? undefined;

    return (
      <div className="Prospects ProspectMeeting" id="Prospects">
        <ProspectView
          pathname={this.props.pathname}
          navigate={this.props.navigate}
          queryParams={this.props.queryParams}
          prospectReference={this.props.prospectReference}
          prospectViewName={prospectViewName}
          insuranceId={insuranceId}
          insuranceDetailId={insuranceDetailId}
          summaryViewName={summaryViewName}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  prospect: state.prospect.prospect,
  isFetchingProspect: state.prospect.isFetchingProspect,
});

type TMapDispatchToProps = {
  fetchProspectByReference: TFetchProspectByReferenceAction;
  updateProspectSuccess: (prospect: IProspect) => any;
  signalRHubListenProspect: TSignalRHubListenProspectAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  fetchProspectByReference: (reference: string) => dispatch(fetchProspectByReference(reference)),
  updateProspectSuccess: (prospect) => dispatch(updateProspectSuccess(prospect)),
  signalRHubListenProspect: (reference: string) => dispatch(signalRHubListenProspect(reference)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")((props: TProspectPresentationProps) => {
  const [queryParams] = useSearchParams();
  const { prospectReference } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <ProspectPresentation {...props} queryParams={queryParams} navigate={navigate} prospectReference={prospectReference} pathname={pathname} />
  );
}));
