import React, { Component } from "react";
import { connect } from "react-redux";
import { TAppRootState } from "../../../redux/types/stateTypes";
import RegisterForm from "../../forms/RegisterForm";
import { registerUser, TRegisterUserAction } from "../../../redux/actions/authentication";

type TRegisterProps = {
  registerUser: TRegisterUserAction;
  isRegistering: boolean;
};

type TRegisterState = {};

class Register extends Component<TRegisterProps, TRegisterState> {
  render() {
    return (
      <div className="Register">
        <RegisterForm registerUser={this.props.registerUser} isRegistering={this.props.isRegistering} />
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  isRegistering: state.authentication.isRegistering,
});

type TMapDispatchToProps = {
  registerUser: TRegisterUserAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  registerUser: (registrationData) => dispatch(registerUser(registrationData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
