import React from "react";
import { useFormikContext } from "formik";
import { withTranslation } from "react-i18next";
import {Form } from "react-bootstrap";
import { OmsenState } from "../../contracts/data/IProspect";
import FormikIndustrialClassificationPicker from "./FormikIndustrialClassificationPicker";
import FormikCountryPicker from "./FormikCountryPicker";
import Beneficiaries from './Beneficiaries';
import FormikInput from './FormikInput';

const LegalPerson = ({ ...props }) => {
  const formikProps = useFormikContext<any>();
  const { t, name} = props;

  return (
    <Form.Group className={'form-group'}>
      <FormikIndustrialClassificationPicker
        name={`${name}.industrialClassificationCode`}
        title={t("Industrial classification")}
        disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
      />
      <FormikCountryPicker name={`${name}.countryOfRegistrationNumber`} title={t("Country Of Registration")} valueType={"number"} disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}/>
      <FormikCountryPicker name={`${name}.countriesOfTaxationNumbers`} title={t("Countries of taxation")} isMulti disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}/>
      <h5 className="AppSidebar-subheader">{t("Company contact")}</h5>
      <FormikInput name={`${name}.contactPersonName`} title={t("Name")} disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}/>
      <FormikInput name={`${name}.contactPersonPhone`} title={t("Phonenumber")} disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}/>
      <FormikInput name={`${name}.contactPersonEmail`} title={t("Email")} disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}/>
      <h5 className="AppSidebar-subheader">{t("Real Beneficiaries")}</h5>
      <Beneficiaries name={`${name}.realBeneficiaries`}/>
    </Form.Group>
  );
};

export default withTranslation("translations")(LegalPerson);
