import { useEffect } from "react";

export type SetPromptMessageFunc = (message: string, enabled: boolean) => void;

interface Props {
  setPromptMessage: SetPromptMessageFunc;
  message: string;
  enabled: boolean;
}

const PromptMessage = (props: Props) => {
  const { message, enabled, setPromptMessage } = props;
  useEffect(() => {
    setPromptMessage(message, enabled);
  }, [message, enabled, setPromptMessage]);

  return null;
};

export default PromptMessage;
