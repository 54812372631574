import React  from 'react';
import {selectCustomStyle} from '../../styles/selectCustomStyle';
import Select from 'react-select';
import {useField, useFormikContext} from 'formik';
import {Form} from 'react-bootstrap';

const FormikSelect = ({ ...props }: any) => {
  const { options } = props;
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const handleChange = async (value: any) => {
    formikProps.setFieldError(field.name, "");
    await formikProps.setFieldValue(field.name, value ? Number(value) : value);
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);
  const existingValues = options.find((option: any) => option[0] === field.value)

  return (
    <Form.Group className={"form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Select
        options={options.map((option: any) => ({value: option[0], label: option[1] }))}
        onChange={(newValue) => newValue && handleChange(newValue.value)}
        value={existingValues ? { value: existingValues[0], label: existingValues[1] } : { value: 0, label: ""}}
        className={isInvalid ? "is-invalid" : "is-valid"}
        onBlur={formikProps.handleBlur}
        styles={selectCustomStyle(isInvalid)}
      />
      <Form.Control.Feedback type="invalid">
        {String(formikProps.errors[field.name]) ?? undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormikSelect;
