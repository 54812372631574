import React, { Component } from "react";
import { Button, Form, Alert, Spinner, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  validateEmail,
  validatePasswordLength,
  validatePasswordLowercaseCount,
  validatePasswordNonAlphaNumericCount,
  validatePasswordUppercaseCount,
} from "../../utils/validatorUtils";
import { TRegisterUserAction } from "../../redux/actions/authentication";
import * as Yup from "yup";
import { applyTranslatedErrorsToForm } from "../../utils/appUtils";

type TRegisterFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  registrationCode: string;
};

type TRegisterFormProps = {
  registerUser: TRegisterUserAction;
  isRegistering: boolean;
} & WithTranslation;

type TRegisterFormState = {
  registerError: string;
  registerSuccess: string;
};

class RegisterForm extends Component<TRegisterFormProps, TRegisterFormState> {
  constructor(props: TRegisterFormProps) {
    super(props);

    this.state = {
      registerError: "",
      registerSuccess: "",
    };
  }

  render() {
    const { t } = this.props;

    const initialValues: TRegisterFormValues = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      registrationCode: "",
    };

    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required(t("Required")),
      lastName: Yup.string().required(t("Required")),
      email: Yup.string().test("test-email", t("Invalid email address"), validateEmail),
      password: Yup.string()
        .required(t("Required"))
        .test("test-password-length", t("Password is too short"), validatePasswordLength)
        .test(
          "test-password-uppercase",
          t("Password must contain at least one uppercase letter"),
          validatePasswordUppercaseCount,
        )
        .test(
          "test-password-lowercase",
          t("Password must contain at least one lowercase letter"),
          validatePasswordLowercaseCount,
        )
        .test(
          "test-password-lowercase",
          t("Password must contain at least one non alphanumeric"),
          validatePasswordNonAlphaNumericCount,
        ),
      confirmPassword: Yup.string()
        .required(t("Required"))
        .oneOf([Yup.ref("password")], t("Passwords must match")),
      registrationCode: Yup.string().required(t("Required")),
    });

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, formikHelpers) => {
          const result = await this.props.registerUser({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            registrationCode: values.registrationCode,
          });
          if (result.errors) {
            const errorMessage =
              applyTranslatedErrorsToForm(result, t, values, formikHelpers) || t("User registration failed");

            this.setState((state) => ({ ...state, registerSuccess: "", registerError: errorMessage }));
          } else {
            this.setState((state) => ({
              ...state,
              registerError: "",
              registerSuccess: t("User registration successful"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete={"off"} noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <Row>
              <Col>
                <Form.Group className={"pb-2 form-group"}>
                  <Form.Label>{t("Firstname")}</Form.Label>
                  <Form.Control
                    name={"firstName"}
                    type={"input"}
                    placeholder={""}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.firstName}
                    isInvalid={!!formikProps.errors.firstName && formikProps.touched.firstName}
                  />
                  <Form.Control.Feedback type="invalid">{formikProps.errors.firstName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className={"pb-2 form-group"}>
                  <Form.Label>{t("Lastname")}</Form.Label>
                  <Form.Control
                    name={"lastName"}
                    type={"input"}
                    placeholder={""}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.lastName}
                    isInvalid={!!formikProps.errors.lastName && formikProps.touched.lastName}
                  />
                  <Form.Control.Feedback type="invalid">{formikProps.errors.lastName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className={"pb-2 form-group"}>
              <Form.Label>{t("Email")}</Form.Label>
              <Form.Control
                name={"email"}
                type={"email"}
                placeholder={""}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.email}
                isInvalid={!!formikProps.errors.email && formikProps.touched.email}
              />
              <Form.Control.Feedback type="invalid">{formikProps.errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className={"pb-2 form-group"}>
                  <Form.Label>{t("Password")}</Form.Label>
                  <Form.Control
                    name={"password"}
                    type={"password"}
                    placeholder={""}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.password}
                    isInvalid={!!formikProps.errors.password && formikProps.touched.password}
                  />
                  <Form.Control.Feedback type="invalid">{formikProps.errors.password}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className={"pb-2 form-group"}>
                  <Form.Label>{t("Confirm Password")}</Form.Label>
                  <Form.Control
                    name={"confirmPassword"}
                    type={"password"}
                    placeholder={""}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.confirmPassword}
                    isInvalid={!!formikProps.errors.confirmPassword && formikProps.touched.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">{formikProps.errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className={"pb-2 form-group"}>
              <Form.Label>{t("Registration code")}</Form.Label>
              <Form.Control
                name={"registrationCode"}
                type={"input"}
                placeholder={""}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.registrationCode}
                isInvalid={!!formikProps.errors.registrationCode && formikProps.touched.registrationCode}
              />
              <Form.Control.Feedback type="invalid">{formikProps.errors.registrationCode}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={"pt-3 form-group"}>
              <Button variant="primary" type="submit" className={"w-100"} disabled={this.props.isRegistering}>
                {this.props.isRegistering ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                  </>
                ) : null}
                {t("Register")}
              </Button>
              {this.state.registerError ? (
                <Alert variant={"danger"} className={"mt-4"}>
                  {this.state.registerError}
                </Alert>
              ) : null}
              {this.state.registerSuccess ? (
                <Alert variant={"success"} className={"mt-4"}>
                  {this.state.registerSuccess}
                </Alert>
              ) : null}
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(RegisterForm);
