import { AnyAction, Reducer } from "redux";
import { TProspectState } from "../types/stateTypes";
import {
  PROSPECT_FETCH_PROSPECT,
  PROSPECT_FETCH_PROSPECT_FAILURE,
  PROSPECT_FETCH_PROSPECT_SUCCESS,
  PROSPECT_FETCH_PROSPECTS,
  PROSPECT_FETCH_PROSPECTS_FAILURE,
  PROSPECT_FETCH_PROSPECTS_SUCCESS,
  PROSPECT_UPDATE_PROSPECT,
  PROSPECT_UPDATE_PROSPECT_FAILURE,
  PROSPECT_UPDATE_PROSPECT_SUCCESS,
  PROSPECT_PUSH_PROSPECT_VIEW,
  PROSPECT_PUSH_PROSPECT_VIEW_FAILURE,
  PROSPECT_PUSH_PROSPECT_VIEW_SUCCESS,
  PROSPECT_CREATE_PROSPECT,
  PROSPECT_CREATE_PROSPECT_SUCCESS,
  PROSPECT_CREATE_PROSPECT_FAILURE,
  PROSPECT_CREATE_PROSPECT_COMMENT,
  PROSPECT_CREATE_PROSPECT_COMMENT_FAILURE,
  PROSPECT_CREATE_PROSPECT_COMMENT_SUCCESS,
  PROSPECT_UPDATE_BASIC_INFORMATION,
  PROSPECT_UPDATE_BASIC_INFORMATION_SUCCESS,
  PROSPECT_UPDATE_BASIC_INFORMATION_FAILURE,
  PROSPECT_FETCH_PROSPECT_EMAIL,
  PROSPECT_FETCH_PROSPECT_EMAIL_SUCCESS,
  PROSPECT_FETCH_PROSPECT_EMAIL_FAILURE,
  PROSPECT_SEND_PROSPECT_EMAIL,
  PROSPECT_SEND_PROSPECT_EMAIL_SUCCESS,
  PROSPECT_SEND_PROSPECT_EMAIL_FAILURE,
  PROSPECT_PUSH_ANALYTICS_DATA,
  PROSPECT_PUSH_ANALYTICS_DATA_SUCCESS,
  PROSPECT_PUSH_ANALYTICS_DATA_FAILURE,
  PROSPECT_FETCH_ANALYTICS_DATA,
  PROSPECT_FETCH_ANALYTICS_DATA_SUCCESS,
  PROSPECT_FETCH_ANALYTICS_DATA_FAILURE,
  PROSPECT_CLEAR_ANALYTICS_DATA,
  PROSPECT_DELETE_PROSPECT_SUCCESS,
  PROSPECT_DELETE_PROSPECT_FAILURE,
  PROSPECT_DELETE_PROSPECT,
  PROSPECT_FETCH_ALL_SELLERS,
  PROSPECT_FETCH_ALL_SELLERS_FAILURE,
  PROSPECT_FETCH_ALL_SELLERS_SUCCESS,
} from "../actions/actionTypes";
import { IProspect } from "../../contracts/data/IProspect";
import { IProspectBase } from "../../contracts/data/IProspectBase";
import { ISeller } from "../../contracts/data/ISeller";
import { IPaginatedProspects } from "../../contracts/data/IPaginatedProspects";
import { IProspectHistory } from "../../contracts/data/IProspectHistory";

export const initialState: TProspectState = {
  prospect: null,
  paginatedProspects: {page: null, pageSize: null, totalAmount: null, items: []},
  sellerList: [],
  isFetchingProspects: false,
  isFetchingAllProspects: false,
  isFetchingProspect: false,
  isUpdatingProspect: false,
  isPushingProspectView: false,
  isCreatingProspect: false,
  isDeletingProspect: false,
  isUpdatingBasicInformation: false,
  isFetchingProspectEmail: false,
  isSendingProspectEmail: false,
  isPushingAnalyticsData: false,
  isFetchingAnalyticsData: false,
  isCreatingProspectComment: false,
  isFetchingAllSellers: false,
};

const addItem = (array: any[], item: any, index = array.length) => {
  let newArray = array.slice();
  newArray.splice(index, 0, item);
  return newArray;
};

const removeItem = (array: any[], index: number) => {
  let newArray = array.slice();
  newArray.splice(index, 1);
  return newArray;
};

const replaceItem = (array: any[], item: any, index: number) => {
  return addItem(removeItem(array, index), item, index);
};

const updateProspectItems = (state: TProspectState, action: AnyAction): TProspectState => {
  const newProspect: IProspect = {
    ...action.payload.prospect,
  };

  const newProspectBase: IProspectBase = {
    ...action.payload.prospect,
  };

  const existingPaginatedProspectIndex = state.paginatedProspects.items.findIndex((p) => p.id === action.payload.prospect.id);

  let newState = state;

  if (existingPaginatedProspectIndex === -1){
    newState = {
      ...newState,
      paginatedProspects: {...newState.paginatedProspects, items: addItem(newState.paginatedProspects.items, newProspectBase)},
    };
  }
  else {
    newState = {
      ...newState,
      paginatedProspects: {...newState.paginatedProspects, items: replaceItem(newState.paginatedProspects.items, newProspectBase, existingPaginatedProspectIndex)},
    };
  }

  return {
    ...newState,
    prospect: newState.prospect === null ? newProspect : { ...newState.prospect, ...newProspect },
  };
};

const fetchProspectSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  return {
    ...updateProspectItems(state, action),
    isFetchingProspect: false,
  };
};

const updateProspectSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  return {
    ...updateProspectItems(state, action),
    isUpdatingProspect: false,
  };
};

const createProspectSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  const newProspectBase: IProspectBase = {
    ...action.payload.prospect,
  };

  return { ...state, paginatedProspects: {...state.paginatedProspects, items: addItem(state.paginatedProspects.items, newProspectBase)}, isCreatingProspect: false };
};

const createProspectCommentSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  const newHistoryItem: IProspectHistory = action.payload.data;
  const prospect = state.paginatedProspects.items.find((prospect) => prospect.id === newHistoryItem.prospectId);

  if (prospect !== undefined && prospect.prospectHistories !== null){
    prospect.prospectHistories.push(newHistoryItem);
    const existingPaginatedProspectIndex = state.paginatedProspects.items.findIndex((p) => p.id === prospect.id);

    return {
      ...state,
      paginatedProspects: {...state.paginatedProspects, items: replaceItem(state.paginatedProspects.items, prospect, existingPaginatedProspectIndex)},
      isCreatingProspectComment: false,
    };
  }
  return {...state, isCreatingProspectComment: false};
};

const deleteProspectSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  const deletedProspectId: string = action.payload.data.id;

  const index = state.paginatedProspects.items.findIndex((prospect) => prospect.id === deletedProspectId);
  if (index > -1) {
    return { ...state, paginatedProspects: {...state.paginatedProspects, items: removeItem(state.paginatedProspects.items, index)}, isDeletingProspect: false };
  } else {
    return { ...state, isDeletingProspect: false };
  }
};

const fetchProspectsSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  const pgtdProspects: IPaginatedProspects = action.payload.prospects;

  return {
    ...state,
    paginatedProspects: {
      ...pgtdProspects
    },
    isFetchingProspects: false,
  }
};

const updateBasicInformationProspectSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  return {
    ...updateProspectItems(state, action),
    isUpdatingBasicInformation: false,
  };
};

const pushAnalyticsDataSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  if (state.prospect !== null) {
    return {
      ...state,
      prospect: {
        ...state.prospect,
        salesAnalyticsData: {
          ...state.prospect.salesAnalyticsData,
          ...action.payload,
        },
      },
      isPushingAnalyticsData: false,
    };
  } else {
    return state;
  }
};

const fetchAnalyticsDataSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  if (state.prospect !== null) {
    return {
      ...state,
      prospect: {
        ...state.prospect,
        salesAnalyticsData: {
          ...state.prospect.salesAnalyticsData,
          ...action.payload,
        },
      },
      isFetchingAnalyticsData: false,
    };
  } else {
    return state;
  }
};

const fetchAllSellersSuccess = (state: TProspectState, action: AnyAction): TProspectState => {
  const allSellers: ISeller[] = action.payload.sellers;

  return {
    ...state,
    sellerList: allSellers,
    isFetchingAllSellers: false,
  };
}

const prospect: Reducer<TProspectState> = (state = initialState, action) => {
  switch (action.type) {
    case PROSPECT_FETCH_PROSPECTS:
      return { ...state, isFetchingProspects: true };
    case PROSPECT_FETCH_PROSPECTS_SUCCESS:
      return fetchProspectsSuccess(state, action);
    case PROSPECT_FETCH_PROSPECTS_FAILURE:
      return { ...state, isFetchingProspects: false };
    case PROSPECT_FETCH_PROSPECT:
      return { ...state, isFetchingProspect: true };
    case PROSPECT_FETCH_PROSPECT_SUCCESS:
      return fetchProspectSuccess(state, action);
    case PROSPECT_FETCH_PROSPECT_FAILURE:
      return { ...state, isFetchingProspect: false };
    case PROSPECT_UPDATE_PROSPECT:
      return { ...state, isUpdatingProspect: true };
    case PROSPECT_UPDATE_PROSPECT_SUCCESS:
      return updateProspectSuccess(state, action);
    case PROSPECT_UPDATE_PROSPECT_FAILURE:
      return { ...state, isUpdatingProspect: false };
    case PROSPECT_PUSH_PROSPECT_VIEW:
      return { ...state, isPushingProspectView: true };
    case PROSPECT_PUSH_PROSPECT_VIEW_SUCCESS:
      return { ...state, isPushingProspectView: false };
    case PROSPECT_PUSH_PROSPECT_VIEW_FAILURE:
      return { ...state, isPushingProspectView: false };
    case PROSPECT_CREATE_PROSPECT:
      return { ...state, isCreatingProspect: true };
    case PROSPECT_CREATE_PROSPECT_SUCCESS:
      return createProspectSuccess(state, action);
    case PROSPECT_CREATE_PROSPECT_FAILURE:
      return { ...state, isCreatingProspect: false };
      case PROSPECT_CREATE_PROSPECT_COMMENT:
      return { ...state, isCreatingProspectComment: true };
    case PROSPECT_CREATE_PROSPECT_COMMENT_SUCCESS:
      return createProspectCommentSuccess(state, action);
    case PROSPECT_CREATE_PROSPECT_COMMENT_FAILURE:
      return { ...state, isCreatingProspectComment: false };
    case PROSPECT_DELETE_PROSPECT:
      return { ...state, isDeletingProspect: true };
    case PROSPECT_DELETE_PROSPECT_SUCCESS:
      return deleteProspectSuccess(state, action);
    case PROSPECT_DELETE_PROSPECT_FAILURE:
      return { ...state, isDeletingProspect: false };
    case PROSPECT_UPDATE_BASIC_INFORMATION:
      return { ...state, isUpdatingBasicInformation: true };
    case PROSPECT_UPDATE_BASIC_INFORMATION_SUCCESS:
      return updateBasicInformationProspectSuccess(state, action);
    case PROSPECT_UPDATE_BASIC_INFORMATION_FAILURE:
      return { ...state, isUpdatingBasicInformation: false };
    case PROSPECT_FETCH_PROSPECT_EMAIL:
      return { ...state, isFetchingProspectEmail: true };
    case PROSPECT_FETCH_PROSPECT_EMAIL_SUCCESS:
      return { ...state, isFetchingProspectEmail: false };
    case PROSPECT_FETCH_PROSPECT_EMAIL_FAILURE:
      return { ...state, isFetchingProspectEmail: false };
    case PROSPECT_SEND_PROSPECT_EMAIL:
      return { ...state, isSendingProspectEmail: true };
    case PROSPECT_SEND_PROSPECT_EMAIL_SUCCESS:
      return { ...state, isSendingProspectEmail: false };
    case PROSPECT_SEND_PROSPECT_EMAIL_FAILURE:
      return { ...state, isSendingProspectEmail: false };
    case PROSPECT_PUSH_ANALYTICS_DATA:
      return { ...state, isPushingAnalyticsData: true };
    case PROSPECT_PUSH_ANALYTICS_DATA_SUCCESS:
      return pushAnalyticsDataSuccess(state, action);
    case PROSPECT_PUSH_ANALYTICS_DATA_FAILURE:
      return { ...state, isPushingAnalyticsData: false };
    case PROSPECT_FETCH_ANALYTICS_DATA:
      return { ...state, isFetchingAnalyticsData: true };
    case PROSPECT_FETCH_ANALYTICS_DATA_SUCCESS:
      return fetchAnalyticsDataSuccess(state, action);
    case PROSPECT_FETCH_ANALYTICS_DATA_FAILURE:
      return { ...state, isFetchingAnalyticsData: false };
    case PROSPECT_CLEAR_ANALYTICS_DATA:
      if (state.prospect) {
        return {
          ...state,
          prospect: {
            ...state.prospect,
            salesAnalyticsData: null,
          },
        };
      }
    case PROSPECT_FETCH_ALL_SELLERS:
      return { ...state, isFetchingAllSellers: true };
    case PROSPECT_FETCH_ALL_SELLERS_SUCCESS:
      return fetchAllSellersSuccess(state, action);
    case PROSPECT_FETCH_ALL_SELLERS_FAILURE:
      return { ...state, isFetchingAllSellers: false };

    default:
      return state;
  }
};

export default prospect;
