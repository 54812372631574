import {
  IAuthenticationService,
  TBooleanResponse,
  TUserResponse,
} from "../../contracts/services/IAuthenticationService";
import { IUser, IUserTokens } from "../../contracts/data/IUser";
import CryptoJS from "crypto-js";
import { IUserRegistrationData } from "../../contracts/data/IUserRegistrationData";
import * as errorCodes from "../../constants/errorCodes";
import { generateRandomId } from "../../utils/appUtils";

interface IMockedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tokens: IUserTokens;
}

export class MockAuthenticationService implements IAuthenticationService {
  private static readonly STORAGE_PREFIX = "insurance_calculator_mock";
  private static readonly USERS_KEY = "users";
  private static readonly REGISTRATION_CODE = "gambit";
  private static readonly VERSION_KEY = "users_version";
  private static readonly VERSION = "4";

  private readonly users: IMockedUser[];

  constructor() {
    this.users = [];
    this.loadFromLocalStorage();
  }

  async login(email: string, password: string): Promise<TUserResponse> {
    const existingUser = this.users.find(
      (user) => user.email === email && user.password === CryptoJS.SHA256(email + password).toString(),
    );

    return existingUser !== undefined ? { data: this.convertMockUserToUser(existingUser) } : {};
  }

  async logout(user: IUser): Promise<TBooleanResponse> {
    return { data: true };
  }

  async validate(user: IUser): Promise<TUserResponse> {
    const existingUser = this.users.find((existingUser) => existingUser.tokens.accessToken === user.tokens.accessToken);
    return existingUser !== undefined ? { data: this.convertMockUserToUser(existingUser) } : {};
  }

  async refresh(user: IUser): Promise<TUserResponse> {
    const existingUser = this.users.find((existingUser) => existingUser.tokens.accessToken === user.tokens.accessToken);
    return existingUser !== undefined ? { data: this.convertMockUserToUser(existingUser) } : {};
  }

  async register(registrationData: IUserRegistrationData): Promise<TUserResponse> {
    if (registrationData.registrationCode !== MockAuthenticationService.REGISTRATION_CODE) {
      return {
        errors: [
          {
            property: "RegistrationCode",
            code: errorCodes.InvalidRegistrationCode,
            message: "Invalid registration code",
          },
        ],
      };
    }

    const existingUser = this.users.find((user) => user.email === registrationData.email);
    if (existingUser !== undefined) {
      return {
        errors: [
          {
            property: null,
            code: errorCodes.UserAlreadyExist,
            message: "A user with that email already exist",
          },
        ],
      };
    }

    const newUser = this.createMockedUserFromRegistrationData(registrationData);
    this.users.push(newUser);

    localStorage.setItem(this.generateStorageKey(MockAuthenticationService.USERS_KEY), JSON.stringify(this.users));

    return { data: this.convertMockUserToUser(newUser) };
  }

  private generateStorageKey(key: string): string {
    return MockAuthenticationService.STORAGE_PREFIX + "_" + key;
  }

  private createMockedUserFromRegistrationData(registrationData: IUserRegistrationData): IMockedUser {
    return {
      id: generateRandomId(),
      firstName: registrationData.firstName,
      lastName: registrationData.lastName,
      email: registrationData.email,
      password: CryptoJS.SHA256(registrationData.email + registrationData.password).toString(),
      tokens: {
        accessToken: CryptoJS.SHA256(registrationData.email).toString(),
        accessTokenExpiration: new Date(2030, 1, 1, 0, 0, 0).getTime(),
        refreshToken: CryptoJS.SHA256(registrationData.email + "refresh").toString(),
      },
    };
  }

  private convertMockUserToUser(mockUser: IMockedUser): IUser {
    return {
      id: mockUser.id,
      firstName: mockUser.firstName,
      lastName: mockUser.lastName,
      email: mockUser.email,
      tokens: mockUser.tokens,
    };
  }

  private loadFromLocalStorage() {
    const versionKey = localStorage.getItem(this.generateStorageKey(MockAuthenticationService.VERSION_KEY));

    if (versionKey !== MockAuthenticationService.VERSION) {
      localStorage.setItem(this.generateStorageKey(MockAuthenticationService.USERS_KEY), JSON.stringify(this.users));
      localStorage.setItem(
        this.generateStorageKey(MockAuthenticationService.VERSION_KEY),
        MockAuthenticationService.VERSION,
      );
    }

    const storedUsersJson = localStorage.getItem(this.generateStorageKey(MockAuthenticationService.USERS_KEY));
    if (storedUsersJson !== null) {
      const storedUsers: IMockedUser[] = JSON.parse(storedUsersJson);
      storedUsers.forEach((user) => this.users.push(user));
    }
  }
}
